import { getConst } from '@tymbe/legislatives/Constants';
import { ContractToShorten } from '@tymbe/legislatives/ContractToShorten';
import { CzechConstantType } from '@tymbe/legislatives/czechia/CzechConstantType';
import { CzechContractType } from '@tymbe/legislatives/czechia/CzechContractType';
import { CzechWorkShiftCheckResult } from '@tymbe/legislatives/czechia/CzechWorkShiftCheckResult';
import { WorkShiftCheckFlags } from '@tymbe/legislatives/WorkShiftCheckFlags';
import moment from 'moment-timezone';
import { ReactNode, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import ClaimRingButton from './ClaimRingButton';
import ConnShowRingDocument from './ShowRingDocument';
import ConnSignDocumentButton from './SignDocumentButton';
import { styled } from '../../../styledComponents';
import { Ring, RingDocument as IRingDocument, RingShiftClaimPrecheck } from '../../../types/Tymbe';
import { formatNumber } from '../../../utils';
import { Error } from '../../Error';
import { Button } from '../../Forms/Button';
import TestModal from '../../Modal/TestModal';
import Spinner from '../../Spinner';
import { H1, H2 } from '../../Styles/BasicElements';
import { Center } from '../../Styles/Center';
import { HoursBar } from '../../Styles/HoursBar';
import { Icon } from '../../Styles/Icon';

const PillButton = styled.button<{ active?: boolean, selected?: boolean }>`
  display: inline-block;
  padding: 1rem;
  background-color: ${({ theme, active, selected }) =>
    // eslint-disable-next-line no-nested-ternary
    (selected ? theme.colors.danger : (active ? theme.colors.primary : theme.colors.gray4))};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0;
  line-height: 0;
  width: 2.3em;
  height: 2.3em;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
`;

const RingDocumentsContainer = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${({ theme }) => theme.colors.gray2};
  @media (max-width: 925px) {
    padding: 1rem 1rem 0 !important;
  }
`;

const Separator = styled(HoursBar).attrs({ hours: 2 })`
display: inline-block;
text-align: center;
  color: ${({ theme }) => theme.colors.gray4};
  margin: 0 0.2rem;
`;

const PillsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-area: pills;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));
`;

const ButtonsContainer = styled.div`
  display: inline-block;
  grid-area: buttons;
  text-align: right;
  *:last-child {
    margin: 0;
  }
`;

const DocumentSelector = styled.div`
  display: grid;
  grid-template-columns: 50% auto 1fr auto;
  grid-template-areas: 'pills header . buttons';
  margin-bottom: 2rem;

  ${H1} {
    grid-area: header;
  }

  @media (max-width: 925px) {
    grid-template-columns: 60% auto;
    grid-template-areas:
      'pills buttons'
      'header header';
    ${H1} {
      margin: 1rem 0 0 !important;
    }
  }
`;

const WarningText = styled.p`
  color: #db0000;
  display: block;
  font-size: 1.3rem;
  margin: auto;
  max-width: 100%;
  width: 1010px;
`;

const timezone = 'Europe/Prague';

const linkify = (chunks: any) => <Link className="underline text-primary contents" to={'/locker'}>{chunks}</Link>;

const textForSalaryLimit = (intl: IntlShape, signUp: CzechWorkShiftCheckResult) => {
  if (signUp.type === CzechContractType.DohodaOProvedeniPrace) {
    return intl.formatMessage({
      defaultMessage: 'Limit výdělku na DPP v jednom měsíci',
      description: 'Informace k limitu hrubé odměny za měsíc na DPP.',
    });
  }
  if (signUp.type === CzechContractType.DohodaOPracovniCinnosti) {
    return intl.formatMessage({
      defaultMessage: 'Limit výdělku na DPČ v jednom měsíci (limit je možné vypnout v profilu)',
      description: 'Informace k limitu hrubé odměny za měsíc na DPČ.',
    });
  }
  return '';
};

const textForTimeLimit = (intl: IntlShape, signUp: CzechWorkShiftCheckResult) => {
  if (signUp.type === CzechContractType.DohodaOProvedeniPrace) {
    return intl.formatMessage({
      defaultMessage: 'Limit odpracovaného času na DPP za kalendářní rok',
      description: 'Informace k limitu odpracovaného času v rámci smlouvy na DPP.',
    });
  }
  if (signUp.type === CzechContractType.DohodaOPracovniCinnosti) {
    return intl.formatMessage({
      defaultMessage: 'Limit odpracovaného času na tuto DPČ',
      description: 'Informace k limitu odpracovaného času v rámci smlouvy na DPČ.',
    });
  }
  return '';
};

export interface RingDocumentsProps {
  ring: Ring;
  claimShiftPrecheck: RingShiftClaimPrecheck;
  signDocument: (ringId: Ring['id'], documentId: IRingDocument['id'], tymbe: boolean, signature: string, passed: string, shorten?: ContractToShorten) => void;
}

export const RingDocuments = ({ ring, signDocument, claimShiftPrecheck, ...rest }: RingDocumentsProps) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [openModal, setOpenModal] = useState(false);
  const [evaluation, setEvaluation] = useState('');
  const [testPassed, setTestPassed] = useState({ status: false, points: '' });
  const [document, selectDocument] = useState<IRingDocument>();

  useEffect(
    () => {
      selectDocument(claimShiftPrecheck.contracts.find((doc) => !doc.signed));
    },
    [claimShiftPrecheck, ring],
  );

  useEffect(() => {
    if (document && document.form_definition && !document.signed) {
      setTestPassed({ status: false, points: '' });
    } else {
      setTestPassed({ status: true, points: '' });
    }
  }, [document]);

  const isActiveOrSigned = (doc: IRingDocument) => document === doc || !!doc?.signed;
  const goBack = () => navigate('/ring');

  if (ring.claimed) {
    return <Navigate to="/before-fight" />;
  }

  const { canSignUp, flags, limits } = claimShiftPrecheck.signUp;

  const unsignedDocExists = claimShiftPrecheck.contracts.find((doc) => !doc.signed);

  const onSign = (signature: string) => {
    if (!document) return;
    const { makeContractChanges } = claimShiftPrecheck.signUp;
    const shorten = document.id === Number(makeContractChanges?.sign.templateId) ? makeContractChanges?.shorten : undefined;
    signDocument(ring.id, document.id, document.tymbe, signature, evaluation, shorten);
  };

  const salaryLimitExceededNow = flags.includes(WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED_NOW);
  const exemptFromInsurance = flags.includes(WorkShiftCheckFlags.EXEMPT_FROM_INSURANCE);
  const shiftIsLastSevenDaysInMonth = moment.tz(ring.time.start, 'europe/prague').endOf('month').add(-7, 'days') < moment(ring.time.start);
  const showSalaryLimitExceededWarning = salaryLimitExceededNow && shiftIsLastSevenDaysInMonth && !exemptFromInsurance;

  if (!canSignUp) {
    let message: ReactNode = claimShiftPrecheck.signUp.issues.map((issue) => <>{issue.message}<small>{issue.extra}</small><br/></>);
    const { individualResults } = claimShiftPrecheck.signUp;
    const dpcResult = individualResults?.find(({ type }) => type === CzechContractType.DohodaOPracovniCinnosti);
    const hppResult = individualResults?.find(({ type }) => type === CzechContractType.HlavniPracovniPomer);

    const dpcOnlySalaryLimitExceeded = dpcResult?.issues.length === 1 && dpcResult.issues[0].flag === WorkShiftCheckFlags.SALARY_LIMIT_EXCEEDED;
    if (dpcOnlySalaryLimitExceeded) {
      const dpcLimit = getConst(CzechConstantType.DPC_LIMIT, moment.tz(timezone).toISOString(), timezone);
      message = intl.formatMessage({
        defaultMessage: 'Touto směnou bys překročil rozhodný příjem {dpcLimit} Kč avšak máš zaplou funkci hlídání jeho překročení. Pokud na směnu opravdu chceš, můžeš si hlídání vypnout ve svém <link>profilu</link>.',
        description: 'Chybová hláška při pokusu o přihlášení na směnu, kterou by došlo k překročení limitu DPČ.',
      }, {
        dpcLimit,
        link: linkify,
      });
    }

    const dpcOnlyHourLimitExceeded = dpcResult?.issues.length === 1 && dpcResult.issues[0].flag === WorkShiftCheckFlags.TIME_LIMIT_EXCEEDED;
    const dpcContractLength = dpcResult?.signedContract ? moment.tz(dpcResult?.signedContract.expiresOn, 'europe/prague').diff(moment.tz(dpcResult?.signedContract.startsOn, 'europe/prague')) : undefined;
    const dpcElapsed = dpcResult?.signedContract ? moment().tz('europe/prague').diff(moment.tz(dpcResult?.signedContract.startsOn, 'europe/prague')) : undefined;
    const dpcHasAtLeastThirdRemaining = dpcResult?.signedContract && dpcElapsed! / dpcContractLength! < 2 / 3;
    const hppOnlyDisallowed = hppResult?.issues.length === 1 && hppResult.issues[0].flag === WorkShiftCheckFlags.CONTRACT_NOT_ALLOWED;
    if (dpcOnlyHourLimitExceeded && dpcHasAtLeastThirdRemaining && hppOnlyDisallowed) {
      message = intl.formatMessage({
        defaultMessage: 'Na DPČ nemůžeš překročit průměr odpracovaných 20 hodin za týden, ale na této pozici je možné pracovat i na pracovní poměr. Napiš nám, pokud se ti tato možnost zamlouvá.',
        description: 'Chybová hláška při pokusu o přihlášení na směnu, kdy je potřeba kontaktovat podporu pro aktivaci HPP.',
      });
    }

    return <Error error={null}>{message}</Error>;
  }

  return (
    <RingDocumentsContainer {...rest}>
      {document && document.form_definition !== null && document.form_definition !== undefined && (
        <TestModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          testPassed={testPassed}
          setTestPassed={setTestPassed}
          testJson={document.form_definition}
          evaluation={evaluation}
          setEvaluation={setEvaluation}
        />
      )}
      <DocumentSelector>
        <PillsContainer>
          {
            claimShiftPrecheck.contracts
              .map((doc, i) => (
                <PillButton
                  key={`${doc.id}_${doc.tymbe}`}
                  active={isActiveOrSigned(doc)}
                  selected={doc === document}
                  onClick={() => selectDocument(doc)}
                >
                  {i + 1}
                </PillButton>
              ))
              .map((doc) => [doc, <Separator key={`${doc.key}_S`} />])
              .flat()
          }
          <PillButton
            active={!unsignedDocExists}
            selected={!document}
            disabled={!!unsignedDocExists}
            onClick={() => selectDocument(undefined)}
          >
            <Icon id="star" color="white" size="1em" />
          </PillButton>
        </PillsContainer>
        <H1 style={{ marginLeft: '2rem' }}>{document?.title}</H1>
        <ButtonsContainer>
          <Button background="secondary" onClick={goBack}>
            {intl.formatMessage({
              defaultMessage: 'Zrušit',
              description: 'Tlačítko potvrzující zrušení přihlášení na směnu',
            })}
          </Button>
          {(document && testPassed.status)
            && (
              <ConnSignDocumentButton onSign={onSign} disabled={!!document?.signed} id={`${document.id}_${document.tymbe}`}>
                <Icon id="sign" size="1.1em" color="white" />&nbsp;
                {!document?.signed
                  ? intl.formatMessage({
                    defaultMessage: 'Podepsat',
                    description: 'Tlačítko pro podepsání smlouvy',
                  })
                  : intl.formatMessage({
                    defaultMessage: 'Podepsáno',
                    description: 'Informace o již podepsané smlouvě',
                  })}
              </ConnSignDocumentButton>
            )}
          {(document && !testPassed.status) && (
            <Button onClick={() => setOpenModal(true)}>
              Vyplnit test
            </Button>
          )}

        </ButtonsContainer>
      </DocumentSelector>
      {document && <ConnShowRingDocument id={document.id} ringId={ring.id} tymbe={document.tymbe} />}
      {!unsignedDocExists && !document && (
        <Center>
          {canSignUp && (
            <>
              <H1>
                {intl.formatMessage({
                  defaultMessage: 'Vše je připraveno.',
                  description: 'Nadpis stranky informující o možnosti se příhlásit na směnu pokud jsou splněny všechny podmínky',
                })}
              </H1>
              {showSalaryLimitExceededWarning ? (
                <WarningText>
                  {intl.formatMessage({
                    defaultMessage: 'Ty nezahálíš! Ale pozor: odpracováním této směny dosáhneš rozhodný příjem a budeš muset hradit nejen odvody na sociální a zdravotní pojištění, ale i doplatek zdravotního pojištění a dohromady už je to docela dost peněz (pár tisíc). Jak z toho ven? Nahrej doklad ve své šatně, pokud jsi student, v evidenci UP, zkrátka pokud jsi státní pojištěnec nebo si hradíš pojištění jinde. Tvá čistá odměna pak bude o poznání vyšší ;)',
                    description: 'Informace o překročení rozhodného limitu.',
                  })}
                </WarningText>
              ) : (
                <H2>
                  {intl.formatMessage({
                    defaultMessage: 'Nyní se už stačí jen přihlásit.',
                    description: 'Informace o možnosti se příhlásit na směnu pokud jsou splněny všechny podmínky.',
                  })}
                </H2>
              )}
              {limits && (
                <>
                  {!limits.allowBypassSalaryLimit && (
                    <div>
                      {intl.formatMessage({
                        defaultMessage: 'Předpokládaná hrubá odměna v daném měsíci (vč. této směny)',
                        description: 'Popis předpokládané hrubé odměny a limitu za měsíc spočtené při přihlašování na směnu.',
                      })}
                      {' '}
                      {intl.formatMessage({
                        defaultMessage: '{total} Kč / {max} Kč',
                        description: 'Předpokládaná hrubá odměna a limit za měsíc spočtená při přihlašování na směnu.',
                      }, {
                        total: formatNumber(intl, limits.totalGrossSalaryThisMonth),
                        max: formatNumber(intl, limits.maxGrossMonthlySalary),
                      })}
                      <span title={textForSalaryLimit(intl, claimShiftPrecheck.signUp)}>
                        <Icon id="info" size="1rem" />
                      </span>
                      <meter
                        min={0}
                        optimum={1}
                        max={Math.max(limits.totalGrossSalaryThisMonth, limits.maxGrossMonthlySalary)}
                        value={limits.totalGrossSalaryThisMonth}
                        high={limits.maxGrossMonthlySalary}
                      />
                    </div>
                  )}
                  <div>
                    {intl.formatMessage({
                      defaultMessage: 'Předpokládaný odpracovaný čas v rámci smlouvy (vč. této směny)',
                      description: 'Popis předpokládaného odpracovaného času a limitu v rámci smlouvy spočteného při přihlašování na směnu.',
                    })}
                    {' '}
                    {intl.formatMessage({
                      defaultMessage: '{total} hod. / {max} hod.',
                      description: 'Předpokladaný odpracovaný čas a limit v rámci smlouvy spočtený při přihlašování na směnu.',
                    }, {
                      total: formatNumber(intl, limits.totalWorkTimeForEntireContractInMinutes / 60),
                      max: formatNumber(intl, limits.maxWorkTimeForEntireContractInMinutes / 60),
                    })}
                    <span title={textForTimeLimit(intl, claimShiftPrecheck.signUp)}>
                      <Icon id="info" size="1rem" />
                    </span>
                    <meter
                      min={0}
                      optimum={1}
                      max={Math.max(limits.totalWorkTimeForEntireContractInMinutes / 60, limits.maxWorkTimeForEntireContractInMinutes / 60)}
                      value={limits.totalWorkTimeForEntireContractInMinutes / 60}
                      high={limits.maxWorkTimeForEntireContractInMinutes / 60}
                    />
                  </div>
                </>
              )}
              <br />
              <ButtonsContainer>
                {showSalaryLimitExceededWarning && (
                  <Button background="secondary" onClick={goBack}>
                    {intl.formatMessage({
                      defaultMessage: 'Na směnu nechci',
                      description: 'Tlačítko potvrzující zrušení přihlášení na směnu',
                    })}
                  </Button>
                )}
                <ClaimRingButton ringId={ring.id} data-cy="acceptShiftButton">
                  {intl.formatMessage({
                    defaultMessage: 'Přihlásit',
                    description: 'Tlačítko pro přihlášení na směnu',
                  })}
                </ClaimRingButton>
              </ButtonsContainer>
            </>
          )}
          {!claimShiftPrecheck
          && <Spinner />}

        </Center>
      )}
    </RingDocumentsContainer>
  );
};
