import {
  ForeignerOrigin,
  EU_COUNTRIES,
  NON_EU_COUNTRIES,
  validCzechCountries,
  CountryCodes,
} from '@tymbe/schema/enums';
import { ShiftLabels } from '@tymbe/schema/shift-template.interface';
import moment from 'moment-timezone';
import { ClipboardEvent, KeyboardEvent } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { Genders, Perks, Ring, Tymber } from './types/Tymbe';

export const getDisplayName = <P>(Component: React.ComponentType<P>) =>
  Component.displayName || Component.name || 'Anonymous';

export const getAge = (birthDate: Date) => {
  const today = new Date();
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const hasPerkFulfilled = (tymber: Tymber, perkId: Perks, ring?: Ring, isDocument?: boolean) => {
  const now = new Date().getTime();
  const ringStart = ring ? moment(ring.time.start).toDate().getTime() : now;
  const ringEnd = ring ? moment(ring.time.end).toDate().getTime() : now;
  const unknowIfIsDocument = isDocument === undefined;

  if (!isDocument || unknowIfIsDocument) {
    switch (perkId) {
      case Perks.ADULT:
        return getAge(new Date(tymber.birthDate)) >= 18;
      case Perks.MAN_ONLY:
        return tymber.gender === Genders.MAN;
      case Perks.WOMAN_ONLY:
        return tymber.gender === Genders.WOMAN;
      case Perks.NON_EU_ONLY:
        return NON_EU_COUNTRIES.includes(tymber.nationality);
      case Perks.EU_ONLY:
        return EU_COUNTRIES.includes(tymber.nationality);
      case Perks.NON_UA_PROTECTED:
        return tymber.foreignerOrigin === ForeignerOrigin.TEMPORARY_PROTECTION_HOLDER_UKRAINE;
      default:
        break;
    }
  }

  if (isDocument || unknowIfIsDocument) {
    return !!tymber.perks?.find((perk) => {
      const perkStart = moment.tz(perk.time.start || undefined, 'europe/prague').startOf('day').toDate().getTime();
      const perkEnd = perk.time.end && moment.tz(perk.time.end, 'europe/prague').endOf('day').toDate().getTime();
      return perk.id === perkId && perkStart <= ringStart && (!perkEnd || perkEnd >= ringEnd);
    });
  }

  return false;
};

export const hasAllPerksFulfilled = (tymber: Tymber, ring: Ring) =>
  ring.perks?.every((perk) => hasPerkFulfilled(tymber, perk.id, ring, perk.isDocument));

// The real range for Brno is '60000-66899' but we change it to include more companines to the search results in Brigady app
// Once we find better solution we can change it back to the original range

export const locationsPairs = {
  '25600-26099': 'Benešov',
  '26600-26899': 'Beroun',
  '67800-68199': 'Blansko',
  '60000-68351': 'Brno',
  '79200-79599': 'Bruntál',
  '69000-69499': 'Břeclav',
  '47000-49999': 'Česká Lípa',
  '37000-37699': 'České Budějovice',
  '38100-38299': 'Český Krumlov',
  '40500-40999': 'Děčín',
  '34400-34699': 'Domažlice',
  '73800-74099': 'Frýdek-Místek',
  '58000-58599': 'Havlíčkův Brod',
  '69500-69999': 'Hodonín',
  '50000-50599': 'Hradec Králové',
  '35000-35599': 'Cheb',
  '43000-43399': 'Chomutov',
  '53700-54099': 'Chrudim',
  '46600-46999': 'Jablonec nad Nisou',
  '79000-79199': 'Jeseník',
  '50600-51299': 'Jičín',
  '58600-59099': 'Jihlava',
  '37700-38099': 'Jindřichův Hradec',
  '36000-36999': 'Karlovy Vary',
  '73300-73799': 'Karviná',
  '27200-27599': 'Kladno',
  '33900-34399': 'Klatovy',
  '28000-28399': 'Kolín',
  '76700-76999': 'Kroměříž',
  '28400-28799': 'Kutná Hora',
  '46000-46599': 'Liberec',
  '41000-41499': 'Litoměřice',
  '44000-45999': 'Louny',
  '27600-27999': 'Mělník',
  '29300-29999': 'Mladá Boleslav',
  '43400-43999': 'Most',
  '54700-55999': 'Náchod',
  '74100-74599': 'Nový Jičín',
  '28800-29299': 'Nymburk',
  '77000-78699': 'Olomouc',
  '74600-74999': 'Opava',
  '70000-73299': 'Ostrava',
  '53000-53699': 'Pardubice',
  '39300-39699': 'Pelhřimov',
  '39700-39999': 'Písek',
  '30000-33999': 'Plzeň',
  '10000-19999': 'Praha - město',
  '25000-25199': 'Praha - východ',
  '25200-25599': 'Praha - západ',
  '38300-38599': 'Prachatice',
  '79600-79999': 'Prostějov',
  '75000-75499': 'Přerov',
  '26100-26599': 'Příbram',
  '26900-27199': 'Rakovník',
  '33700-33899': 'Rokycany',
  '51600-52999': 'Rychnov nad Kněžnou',
  '51300-51599': 'Semily',
  '35600-35999': 'Sokolov',
  '38600-38999': 'Strakonice',
  '56800-57999': 'Svitavy',
  '78700-78999': 'Šumperk',
  '34700-34999': 'Tachov',
  '39000-39299': 'Tábor',
  '41500-42999': 'Teplice',
  '54100-54699': 'Trutnov',
  '67400-67799': 'Třebíč',
  '68600-68999': 'Uherské Hradiště',
  '40000-40499': 'Ústí nad Labem',
  '56000-56799': 'Ústí nad Orlicí',
  '75500-75999': 'Vsetín',
  '68200-68599': 'Vyškov',
  '76000-76699': 'Zlín',
  '66900-67399': 'Znojmo',
  '59100-59999': 'Žďár nad Sázavou',
};

export const tymberDocumentPerkPairs: { [k: string]: [number, string] } = {
  [Perks.ANTIGEN_TEST]: [1, 'COVID-19 test'],
  [Perks.HEALTH_CARD]: [2, 'Potravinářský průkaz'],
  [Perks.HEALTH_INSURANCE]: [3, 'Kartička pojištěnce'],
  [Perks.CRIMINAL_RECORD]: [4, 'Výpis z rejstříku trestů'],
  [Perks.DRIVING_LICENSE_B]: [5, 'Řidičský průkaz typu B'],
  [Perks.FORKLIFT]: [6, 'Vysokozdvižný vozík'],
  [Perks.MINIFORKLIFT]: [7, 'Paletový vozík'],
  [Perks.ID_CARD]: [8, 'Občanský průkaz'],
};

export const loadLocaleData = (locale: string) => {
  switch (locale) {
    case 'uk':
      return import('./lang/uk.json').then((data) => data);
    default:
      return import('./lang/cs.json').then((data) => data);
  }
};
export const revertNumber = (num: number) => {
  if (num <= 0) return Math.abs(num);
  return -Math.abs(num);
};

export function formatOnPasteForZip(event: ClipboardEvent) {
  event.preventDefault();
  const newValue = event.clipboardData.getData('text/plain').replace(/ /g, '').replace(/\D+/g, '');
  const input = event.target as HTMLInputElement;
  const lastValue = input.value;
  input.value = newValue;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  input.dispatchEvent(new Event('input', { bubbles: true }));
}

export function blockInvalidCharactersForZip(event: KeyboardEvent<HTMLInputElement>) {
  if (
    event.key === ' '
    || (!event.ctrlKey && ![0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'Backspace'].map(String).includes(event.key))
  ) {
    event.preventDefault();
  }
}

export const isValidGeneralZipCode = (zipCode: string) => /^\d+$/.test(zipCode);
export const isValidCzechZipCode = (zipCode: string) => /^\d{5}$/.test(zipCode);

export const validateZipCode = (zipCode: string, country: CountryCodes) => {
  if (validCzechCountries.includes(country)) {
    return isValidCzechZipCode(zipCode);
  }
  return isValidGeneralZipCode(zipCode);
};

export const CountryCodesAndNames = () => {
  const intl = useIntl();

  const countryCodesList = {
    [CountryCodes.CZE]: intl.formatMessage({
      defaultMessage: 'Česko',
    }),
    [CountryCodes.SVK]: intl.formatMessage({
      defaultMessage: 'Slovensko',
    }),
    [CountryCodes.UKR]: intl.formatMessage({
      defaultMessage: 'Ukrajina',
    }),
    [CountryCodes.AFG]: intl.formatMessage({
      defaultMessage: 'Afghánistán',
    }),
    [CountryCodes.ALA]: intl.formatMessage({
      defaultMessage: 'Alandys',
    }),
    [CountryCodes.ALB]: intl.formatMessage({
      defaultMessage: 'Albánie',
    }),
    [CountryCodes.DZA]: intl.formatMessage({
      defaultMessage: 'Alžírsko',
    }),
    [CountryCodes.ASM]: intl.formatMessage({
      defaultMessage: 'Americká Samoa',
    }),
    [CountryCodes.VIR]: intl.formatMessage({
      defaultMessage: 'Americké Panenské ostrovy',
    }),
    [CountryCodes.AND]: intl.formatMessage({
      defaultMessage: 'Andorra',
    }),
    [CountryCodes.AGO]: intl.formatMessage({
      defaultMessage: 'Angola',
    }),
    [CountryCodes.AIA]: intl.formatMessage({
      defaultMessage: 'Anguilla',
    }),
    [CountryCodes.ATA]: intl.formatMessage({
      defaultMessage: 'Antarktida',
    }),
    [CountryCodes.ATG]: intl.formatMessage({
      defaultMessage: 'Antigua a Barbuda',
    }),
    [CountryCodes.ARG]: intl.formatMessage({
      defaultMessage: 'Argentina',
    }),
    [CountryCodes.ARM]: intl.formatMessage({
      defaultMessage: 'Arménie',
    }),
    [CountryCodes.ABW]: intl.formatMessage({
      defaultMessage: 'Aruba',
    }),
    [CountryCodes.AUS]: intl.formatMessage({
      defaultMessage: 'Austrálie',
    }),
    [CountryCodes.AZE]: intl.formatMessage({
      defaultMessage: 'Ázerbájdžán',
    }),
    [CountryCodes.BHS]: intl.formatMessage({
      defaultMessage: 'Bahamy',
    }),
    [CountryCodes.BHR]: intl.formatMessage({
      defaultMessage: 'Bahrajn',
    }),
    [CountryCodes.BGD]: intl.formatMessage({
      defaultMessage: 'Bangladéš',
    }),
    [CountryCodes.BRB]: intl.formatMessage({
      defaultMessage: 'Barbados',
    }),
    [CountryCodes.BEL]: intl.formatMessage({
      defaultMessage: 'Belgie',
    }),
    [CountryCodes.BLZ]: intl.formatMessage({
      defaultMessage: 'Belize',
    }),
    [CountryCodes.BLR]: intl.formatMessage({
      defaultMessage: 'Bělorusko',
    }),
    [CountryCodes.BEN]: intl.formatMessage({
      defaultMessage: 'Benin',
    }),
    [CountryCodes.BMU]: intl.formatMessage({
      defaultMessage: 'Bermudy',
    }),
    [CountryCodes.BTN]: intl.formatMessage({
      defaultMessage: 'Bhútán',
    }),
    [CountryCodes.BOL]: intl.formatMessage({
      defaultMessage: 'Bolívie',
    }),
    [CountryCodes.BES]: intl.formatMessage({
      defaultMessage: 'Bonaire, Svatý Eustach a Saba',
    }),
    [CountryCodes.BIH]: intl.formatMessage({
      defaultMessage: 'Bosna a Hercegovina',
    }),
    [CountryCodes.BWA]: intl.formatMessage({
      defaultMessage: 'Botswana',
    }),
    [CountryCodes.BVT]: intl.formatMessage({
      defaultMessage: 'Bouvetův ostrov',
    }),
    [CountryCodes.BRA]: intl.formatMessage({
      defaultMessage: 'Brazílie',
    }),
    [CountryCodes.IOT]: intl.formatMessage({
      defaultMessage: 'Britské indickooceánské území',
    }),
    [CountryCodes.VGB]: intl.formatMessage({
      defaultMessage: 'Britské Panenské ostrovy',
    }),
    [CountryCodes.BRN]: intl.formatMessage({
      defaultMessage: 'Brunej',
    }),
    [CountryCodes.BGR]: intl.formatMessage({
      defaultMessage: 'Bulharsko',
    }),
    [CountryCodes.BFA]: intl.formatMessage({
      defaultMessage: 'Burkina Faso',
    }),
    [CountryCodes.BDI]: intl.formatMessage({
      defaultMessage: 'Burundi',
    }),
    [CountryCodes.COK]: intl.formatMessage({
      defaultMessage: 'Cookovy ostrovy',
    }),
    [CountryCodes.CUW]: intl.formatMessage({
      defaultMessage: 'Curaçao',
    }),
    [CountryCodes.TCD]: intl.formatMessage({
      defaultMessage: 'Čad',
    }),
    [CountryCodes.MNE]: intl.formatMessage({
      defaultMessage: 'Černá Hora',
    }),
    [CountryCodes.CHN]: intl.formatMessage({
      defaultMessage: 'Čína',
    }),
    [CountryCodes.DNK]: intl.formatMessage({
      defaultMessage: 'Dánsko',
    }),
    [CountryCodes.COD]: intl.formatMessage({
      defaultMessage: 'Konžská demokratická republika',
    }),
    [CountryCodes.DMA]: intl.formatMessage({
      defaultMessage: 'Dominika',
    }),
    [CountryCodes.DOM]: intl.formatMessage({
      defaultMessage: 'Dominikánská republika',
    }),
    [CountryCodes.DJI]: intl.formatMessage({
      defaultMessage: 'Džibutsko',
    }),
    [CountryCodes.EGY]: intl.formatMessage({
      defaultMessage: 'Egypt',
    }),
    [CountryCodes.ECU]: intl.formatMessage({
      defaultMessage: 'Ekvádor',
    }),
    [CountryCodes.ERI]: intl.formatMessage({
      defaultMessage: 'Eritrea',
    }),
    [CountryCodes.EST]: intl.formatMessage({
      defaultMessage: 'Estonsko',
    }),
    [CountryCodes.ETH]: intl.formatMessage({
      defaultMessage: 'Etiopie',
    }),
    [CountryCodes.FRO]: intl.formatMessage({
      defaultMessage: 'Faerské ostrovy',
    }),
    [CountryCodes.FLK]: intl.formatMessage({
      defaultMessage: 'Falklandy (Malvíny)',
    }),
    [CountryCodes.FJI]: intl.formatMessage({
      defaultMessage: 'Fidži',
    }),
    [CountryCodes.PHL]: intl.formatMessage({
      defaultMessage: 'Filipíny',
    }),
    [CountryCodes.FIN]: intl.formatMessage({
      defaultMessage: 'Finsko',
    }),
    [CountryCodes.FRA]: intl.formatMessage({
      defaultMessage: 'Francie',
    }),
    [CountryCodes.GUF]: intl.formatMessage({
      defaultMessage: 'Francouzská Guyana',
    }),
    [CountryCodes.ATF]: intl.formatMessage({
      defaultMessage: 'Francouzská jižní a antarktická území',
    }),
    [CountryCodes.PYF]: intl.formatMessage({
      defaultMessage: 'Francouzská Polynésie',
    }),
    [CountryCodes.GAB]: intl.formatMessage({
      defaultMessage: 'Gabon',
    }),
    [CountryCodes.GMB]: intl.formatMessage({
      defaultMessage: 'Gambie',
    }),
    [CountryCodes.GHA]: intl.formatMessage({
      defaultMessage: 'Ghana',
    }),
    [CountryCodes.GIB]: intl.formatMessage({
      defaultMessage: 'Gibraltar',
    }),
    [CountryCodes.GRD]: intl.formatMessage({
      defaultMessage: 'Grenada',
    }),
    [CountryCodes.GRL]: intl.formatMessage({
      defaultMessage: 'Grónsko',
    }),
    [CountryCodes.GEO]: intl.formatMessage({
      defaultMessage: 'Gruzie',
    }),
    [CountryCodes.GLP]: intl.formatMessage({
      defaultMessage: 'Guadeloupe',
    }),
    [CountryCodes.GUM]: intl.formatMessage({
      defaultMessage: 'Guam',
    }),
    [CountryCodes.GTM]: intl.formatMessage({
      defaultMessage: 'Guatemala',
    }),
    [CountryCodes.GGY]: intl.formatMessage({
      defaultMessage: 'Guernsey',
    }),
    [CountryCodes.GIN]: intl.formatMessage({
      defaultMessage: 'Guinea',
    }),
    [CountryCodes.GNB]: intl.formatMessage({
      defaultMessage: 'Guinea-Bissau',
    }),
    [CountryCodes.GUY]: intl.formatMessage({
      defaultMessage: 'Guyana',
    }),
    [CountryCodes.HTI]: intl.formatMessage({
      defaultMessage: 'Haiti',
    }),
    [CountryCodes.HMD]: intl.formatMessage({
      defaultMessage: 'Heardův ostrov a MacDonaldovy ostrovy',
    }),
    [CountryCodes.HND]: intl.formatMessage({
      defaultMessage: 'Honduras',
    }),
    [CountryCodes.HKG]: intl.formatMessage({
      defaultMessage: 'Hongkong',
    }),
    [CountryCodes.CHL]: intl.formatMessage({
      defaultMessage: 'Chile',
    }),
    [CountryCodes.HRV]: intl.formatMessage({
      defaultMessage: 'Chorvatsko',
    }),
    [CountryCodes.IND]: intl.formatMessage({
      defaultMessage: 'Indie',
    }),
    [CountryCodes.IDN]: intl.formatMessage({
      defaultMessage: 'Indonésie',
    }),
    [CountryCodes.IRQ]: intl.formatMessage({
      defaultMessage: 'Irák',
    }),
    [CountryCodes.IRN]: intl.formatMessage({
      defaultMessage: 'Írán',
    }),
    [CountryCodes.IRL]: intl.formatMessage({
      defaultMessage: 'Irsko',
    }),
    [CountryCodes.ISL]: intl.formatMessage({
      defaultMessage: 'Island',
    }),
    [CountryCodes.ITA]: intl.formatMessage({
      defaultMessage: 'Itálie',
    }),
    [CountryCodes.ISR]: intl.formatMessage({
      defaultMessage: 'Izrael',
    }),
    [CountryCodes.JAM]: intl.formatMessage({
      defaultMessage: 'Jamajka',
    }),
    [CountryCodes.JPN]: intl.formatMessage({
      defaultMessage: 'Japonsko',
    }),
    [CountryCodes.YEM]: intl.formatMessage({
      defaultMessage: 'Jemen',
    }),
    [CountryCodes.JEY]: intl.formatMessage({
      defaultMessage: 'Jersey',
    }),
    [CountryCodes.ZAF]: intl.formatMessage({
      defaultMessage: 'Jižní Afrika',
    }),
    [CountryCodes.SGS]: intl.formatMessage({
      defaultMessage: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
    }),
    [CountryCodes.SSD]: intl.formatMessage({
      defaultMessage: 'Jižní Súdán',
    }),
    [CountryCodes.JOR]: intl.formatMessage({
      defaultMessage: 'Jordánsko',
    }),
    [CountryCodes.CYM]: intl.formatMessage({
      defaultMessage: 'Kajmanské ostrovy',
    }),
    [CountryCodes.KHM]: intl.formatMessage({
      defaultMessage: 'Kambodža',
    }),
    [CountryCodes.CMR]: intl.formatMessage({
      defaultMessage: 'Kamerun',
    }),
    [CountryCodes.CAN]: intl.formatMessage({
      defaultMessage: 'Kanada',
    }),
    [CountryCodes.CPV]: intl.formatMessage({
      defaultMessage: 'Kapverdy',
    }),
    [CountryCodes.QAT]: intl.formatMessage({
      defaultMessage: 'Katar',
    }),
    [CountryCodes.KAZ]: intl.formatMessage({
      defaultMessage: 'Kazachstán',
    }),
    [CountryCodes.KEN]: intl.formatMessage({
      defaultMessage: 'Keňa',
    }),
    [CountryCodes.KIR]: intl.formatMessage({
      defaultMessage: 'Kiribati',
    }),
    [CountryCodes.CCK]: intl.formatMessage({
      defaultMessage: 'Kokosové (Keelingovy) ostrovy',
    }),
    [CountryCodes.COL]: intl.formatMessage({
      defaultMessage: 'Kolumbie',
    }),
    [CountryCodes.COM]: intl.formatMessage({
      defaultMessage: 'Komory',
    }),
    [CountryCodes.COG]: intl.formatMessage({
      defaultMessage: 'Konžská republika',
    }),
    [CountryCodes.PRK]: intl.formatMessage({
      defaultMessage: 'Korejská lidově demokratická republika',
    }),
    [CountryCodes.KOR]: intl.formatMessage({
      defaultMessage: 'Korejská republika',
    }),
    [CountryCodes.XXK]: intl.formatMessage({
      defaultMessage: 'Kosovo',
    }),
    [CountryCodes.CRI]: intl.formatMessage({
      defaultMessage: 'Kostarika',
    }),
    [CountryCodes.CUB]: intl.formatMessage({
      defaultMessage: 'Kuba',
    }),
    [CountryCodes.KWT]: intl.formatMessage({
      defaultMessage: 'Kuvajt',
    }),
    [CountryCodes.CYP]: intl.formatMessage({
      defaultMessage: 'Kypr',
    }),
    [CountryCodes.KGZ]: intl.formatMessage({
      defaultMessage: 'Kyrgyzstán',
    }),
    [CountryCodes.LAO]: intl.formatMessage({
      defaultMessage: 'Laos',
    }),
    [CountryCodes.LSO]: intl.formatMessage({
      defaultMessage: 'Lesotho',
    }),
    [CountryCodes.LBN]: intl.formatMessage({
      defaultMessage: 'Libanon',
    }),
    [CountryCodes.LBR]: intl.formatMessage({
      defaultMessage: 'Libérie',
    }),
    [CountryCodes.LBY]: intl.formatMessage({
      defaultMessage: 'Libye',
    }),
    [CountryCodes.LIE]: intl.formatMessage({
      defaultMessage: 'Lichtenštejnsko',
    }),
    [CountryCodes.LTU]: intl.formatMessage({
      defaultMessage: 'Litva',
    }),
    [CountryCodes.LVA]: intl.formatMessage({
      defaultMessage: 'Lotyšsko',
    }),
    [CountryCodes.LUX]: intl.formatMessage({
      defaultMessage: 'Lucembursko',
    }),
    [CountryCodes.MAC]: intl.formatMessage({
      defaultMessage: 'Macao',
    }),
    [CountryCodes.MDG]: intl.formatMessage({
      defaultMessage: 'Madagaskar',
    }),
    [CountryCodes.HUN]: intl.formatMessage({
      defaultMessage: 'Maďarsko',
    }),
    [CountryCodes.MKD]: intl.formatMessage({
      defaultMessage: 'Severní Makedonie',
    }),
    [CountryCodes.MYS]: intl.formatMessage({
      defaultMessage: 'Malajsie',
    }),
    [CountryCodes.MWI]: intl.formatMessage({
      defaultMessage: 'Malawi',
    }),
    [CountryCodes.MDV]: intl.formatMessage({
      defaultMessage: 'Maledivy',
    }),
    [CountryCodes.MLI]: intl.formatMessage({
      defaultMessage: 'Mali',
    }),
    [CountryCodes.MLT]: intl.formatMessage({
      defaultMessage: 'Malta',
    }),
    [CountryCodes.IMN]: intl.formatMessage({
      defaultMessage: 'Man',
    }),
    [CountryCodes.MAR]: intl.formatMessage({
      defaultMessage: 'Maroko',
    }),
    [CountryCodes.MHL]: intl.formatMessage({
      defaultMessage: 'Marshallovy ostrovy',
    }),
    [CountryCodes.MTQ]: intl.formatMessage({
      defaultMessage: 'Martinik',
    }),
    [CountryCodes.MUS]: intl.formatMessage({
      defaultMessage: 'Mauricius',
    }),
    [CountryCodes.MRT]: intl.formatMessage({
      defaultMessage: 'Mauritánie',
    }),
    [CountryCodes.MYT]: intl.formatMessage({
      defaultMessage: 'Mayotte',
    }),
    [CountryCodes.UMI]: intl.formatMessage({
      defaultMessage: 'Menší odlehlé ostrovy USA',
    }),
    [CountryCodes.MEX]: intl.formatMessage({
      defaultMessage: 'Mexiko',
    }),
    [CountryCodes.FSM]: intl.formatMessage({
      defaultMessage: 'Mikronésie',
    }),
    [CountryCodes.MDA]: intl.formatMessage({
      defaultMessage: 'Moldavsko',
    }),
    [CountryCodes.MCO]: intl.formatMessage({
      defaultMessage: 'Monako',
    }),
    [CountryCodes.MNG]: intl.formatMessage({
      defaultMessage: 'Mongolsko',
    }),
    [CountryCodes.MSR]: intl.formatMessage({
      defaultMessage: 'Montserrat',
    }),
    [CountryCodes.MOZ]: intl.formatMessage({
      defaultMessage: 'Mosambik',
    }),
    [CountryCodes.MMR]: intl.formatMessage({
      defaultMessage: 'Myanmar',
    }),
    [CountryCodes.NAM]: intl.formatMessage({
      defaultMessage: 'Namibie',
    }),
    [CountryCodes.NRU]: intl.formatMessage({
      defaultMessage: 'Nauru',
    }),
    [CountryCodes.DEU]: intl.formatMessage({
      defaultMessage: 'Německo',
    }),
    [CountryCodes.NPL]: intl.formatMessage({
      defaultMessage: 'Nepál',
    }),
    [CountryCodes.NER]: intl.formatMessage({
      defaultMessage: 'Niger',
    }),
    [CountryCodes.NGA]: intl.formatMessage({
      defaultMessage: 'Nigérie',
    }),
    [CountryCodes.NIC]: intl.formatMessage({
      defaultMessage: 'Nikaragua',
    }),
    [CountryCodes.NIU]: intl.formatMessage({
      defaultMessage: 'Niue',
    }),
    [CountryCodes.NLD]: intl.formatMessage({
      defaultMessage: 'Nizozemsko',
    }),
    [CountryCodes.NFK]: intl.formatMessage({
      defaultMessage: 'Norfolk',
    }),
    [CountryCodes.NOR]: intl.formatMessage({
      defaultMessage: 'Norsko',
    }),
    [CountryCodes.NCL]: intl.formatMessage({
      defaultMessage: 'Nová Kaledonie',
    }),
    [CountryCodes.NZL]: intl.formatMessage({
      defaultMessage: 'Nový Zéland',
    }),
    [CountryCodes.OMN]: intl.formatMessage({
      defaultMessage: 'Omán',
    }),
    [CountryCodes.PAK]: intl.formatMessage({
      defaultMessage: 'Pákistán',
    }),
    [CountryCodes.PLW]: intl.formatMessage({
      defaultMessage: 'Palau',
    }),
    [CountryCodes.PSE]: intl.formatMessage({
      defaultMessage: 'Palestina',
    }),
    [CountryCodes.PAN]: intl.formatMessage({
      defaultMessage: 'Panama',
    }),
    [CountryCodes.PNG]: intl.formatMessage({
      defaultMessage: 'Papua Nová Guinea',
    }),
    [CountryCodes.PRY]: intl.formatMessage({
      defaultMessage: 'Paraguay',
    }),
    [CountryCodes.PER]: intl.formatMessage({
      defaultMessage: 'Peru',
    }),
    [CountryCodes.PCN]: intl.formatMessage({
      defaultMessage: 'Pitcairn',
    }),
    [CountryCodes.CIV]: intl.formatMessage({
      defaultMessage: 'Pobřeží slonoviny',
    }),
    [CountryCodes.POL]: intl.formatMessage({
      defaultMessage: 'Polsko',
    }),
    [CountryCodes.PRI]: intl.formatMessage({
      defaultMessage: 'Portoriko',
    }),
    [CountryCodes.PRT]: intl.formatMessage({
      defaultMessage: 'Portugalsko',
    }),
    [CountryCodes.AUT]: intl.formatMessage({
      defaultMessage: 'Rakousko',
    }),
    [CountryCodes.REU]: intl.formatMessage({
      defaultMessage: 'Réunion',
    }),
    [CountryCodes.GNQ]: intl.formatMessage({
      defaultMessage: 'Rovníková Guinea',
    }),
    [CountryCodes.ROU]: intl.formatMessage({
      defaultMessage: 'Rumunsko',
    }),
    [CountryCodes.RUS]: intl.formatMessage({
      defaultMessage: 'Rusko',
    }),
    [CountryCodes.RWA]: intl.formatMessage({
      defaultMessage: 'Rwanda',
    }),
    [CountryCodes.GRC]: intl.formatMessage({
      defaultMessage: 'Řecko',
    }),
    [CountryCodes.SPM]: intl.formatMessage({
      defaultMessage: 'Saint Pierre a Miquelon',
    }),
    [CountryCodes.SLV]: intl.formatMessage({
      defaultMessage: 'Salvador',
    }),
    [CountryCodes.WSM]: intl.formatMessage({
      defaultMessage: 'Samoa',
    }),
    [CountryCodes.SMR]: intl.formatMessage({
      defaultMessage: 'San Marino',
    }),
    [CountryCodes.SAU]: intl.formatMessage({
      defaultMessage: 'Saúdská Arábie',
    }),
    [CountryCodes.SEN]: intl.formatMessage({
      defaultMessage: 'Senegal',
    }),
    [CountryCodes.MNP]: intl.formatMessage({
      defaultMessage: 'Severní Mariany',
    }),
    [CountryCodes.SYC]: intl.formatMessage({
      defaultMessage: 'Seychely',
    }),
    [CountryCodes.SLE]: intl.formatMessage({
      defaultMessage: 'Sierra Leone',
    }),
    [CountryCodes.SGP]: intl.formatMessage({
      defaultMessage: 'Singapur',
    }),
    [CountryCodes.SVN]: intl.formatMessage({
      defaultMessage: 'Slovinsko',
    }),
    [CountryCodes.SOM]: intl.formatMessage({
      defaultMessage: 'Somálsko',
    }),
    [CountryCodes.ARE]: intl.formatMessage({
      defaultMessage: 'Spojené arabské emiráty',
    }),
    [CountryCodes.USA]: intl.formatMessage({
      defaultMessage: 'Spojené státy',
    }),
    [CountryCodes.SRB]: intl.formatMessage({
      defaultMessage: 'Srbsko',
    }),
    [CountryCodes.CAF]: intl.formatMessage({
      defaultMessage: 'Středoafrická republika',
    }),
    [CountryCodes.SDN]: intl.formatMessage({
      defaultMessage: 'Súdán',
    }),
    [CountryCodes.SUR]: intl.formatMessage({
      defaultMessage: 'Surinam',
    }),
    [CountryCodes.SHN]: intl.formatMessage({
      defaultMessage: 'Svatá Helena',
    }),
    [CountryCodes.LCA]: intl.formatMessage({
      defaultMessage: 'Svatá Lucie',
    }),
    [CountryCodes.BLM]: intl.formatMessage({
      defaultMessage: 'Svatý Bartoloměj',
    }),
    [CountryCodes.KNA]: intl.formatMessage({
      defaultMessage: 'Svatý Kryštof a Nevis',
    }),
    [CountryCodes.MAF]: intl.formatMessage({
      defaultMessage: 'Svatý Martin (FR)',
    }),
    [CountryCodes.SXM]: intl.formatMessage({
      defaultMessage: 'Svatý Martin (NL)',
    }),
    [CountryCodes.STP]: intl.formatMessage({
      defaultMessage: 'Svatý Tomáš a Princův ostrov',
    }),
    [CountryCodes.VCT]: intl.formatMessage({
      defaultMessage: 'Svatý Vincenc a Grenadiny',
    }),
    [CountryCodes.SWZ]: intl.formatMessage({
      defaultMessage: 'Svazijsko',
    }),
    [CountryCodes.SYR]: intl.formatMessage({
      defaultMessage: 'Sýrie',
    }),
    [CountryCodes.SLB]: intl.formatMessage({
      defaultMessage: 'Šalomounovy ostrovy',
    }),
    [CountryCodes.ESP]: intl.formatMessage({
      defaultMessage: 'Španělsko',
    }),
    [CountryCodes.SJM]: intl.formatMessage({
      defaultMessage: 'Špicberky a Jan Mayen',
    }),
    [CountryCodes.LKA]: intl.formatMessage({
      defaultMessage: 'Šrí Lanka',
    }),
    [CountryCodes.SWE]: intl.formatMessage({
      defaultMessage: 'Švédsko',
    }),
    [CountryCodes.CHE]: intl.formatMessage({
      defaultMessage: 'Švýcarsko',
    }),
    [CountryCodes.TJK]: intl.formatMessage({
      defaultMessage: 'Tádžikistán',
    }),
    [CountryCodes.TZA]: intl.formatMessage({
      defaultMessage: 'Tanzanie',
    }),
    [CountryCodes.THA]: intl.formatMessage({
      defaultMessage: 'Thajsko',
    }),
    [CountryCodes.TWN]: intl.formatMessage({
      defaultMessage: 'Tchaj-wan',
    }),
    [CountryCodes.TGO]: intl.formatMessage({
      defaultMessage: 'Togo',
    }),
    [CountryCodes.TKL]: intl.formatMessage({
      defaultMessage: 'Tokelau',
    }),
    [CountryCodes.TON]: intl.formatMessage({
      defaultMessage: 'Tonga',
    }),
    [CountryCodes.TTO]: intl.formatMessage({
      defaultMessage: 'Trinidad a Tobago',
    }),
    [CountryCodes.TUN]: intl.formatMessage({
      defaultMessage: 'Tunisko',
    }),
    [CountryCodes.TUR]: intl.formatMessage({
      defaultMessage: 'Turecko',
    }),
    [CountryCodes.TKM]: intl.formatMessage({
      defaultMessage: 'Turkmenistán',
    }),
    [CountryCodes.TCA]: intl.formatMessage({
      defaultMessage: 'Turks a Caicos',
    }),
    [CountryCodes.TUV]: intl.formatMessage({
      defaultMessage: 'Tuvalu',
    }),
    [CountryCodes.UGA]: intl.formatMessage({
      defaultMessage: 'Uganda',
    }),
    [CountryCodes.URY]: intl.formatMessage({
      defaultMessage: 'Uruguay',
    }),
    [CountryCodes.UZB]: intl.formatMessage({
      defaultMessage: 'Uzbekistán',
    }),
    [CountryCodes.CXR]: intl.formatMessage({
      defaultMessage: 'Vánoční ostrov',
    }),
    [CountryCodes.VUT]: intl.formatMessage({
      defaultMessage: 'Vanuatu',
    }),
    [CountryCodes.VAT]: intl.formatMessage({
      defaultMessage: 'Vatikán',
    }),
    [CountryCodes.GBR]: intl.formatMessage({
      defaultMessage: 'Velká Británie',
    }),
    [CountryCodes.VEN]: intl.formatMessage({
      defaultMessage: 'Venezuela',
    }),
    [CountryCodes.VNM]: intl.formatMessage({
      defaultMessage: 'Vietnam',
    }),
    [CountryCodes.TLS]: intl.formatMessage({
      defaultMessage: 'Východní Timor',
    }),
    [CountryCodes.WLF]: intl.formatMessage({
      defaultMessage: 'Wallis a Futuna',
    }),
    [CountryCodes.ZMB]: intl.formatMessage({
      defaultMessage: 'Zambie',
    }),
    [CountryCodes.ESH]: intl.formatMessage({
      defaultMessage: 'Západní Sahara',
    }),
    [CountryCodes.ZWE]: intl.formatMessage({
      defaultMessage: 'Zimbabwe',
    }),
  };
  return countryCodesList;
};

export const selectCountryOptions = () => {
  const countryOptions = Object.entries(CountryCodesAndNames()).map(([k, v]) => ({ label: v, value: k }));
  return countryOptions;
};

export const getCountryNameFromCode = (countryCode?: CountryCodes | null): string => {
  const countryCodesAndNames = CountryCodesAndNames();
  return countryCode ? countryCodesAndNames[countryCode] : '';
};

export const shiftLabelsText: Record<string, string> = {
  [ShiftLabels.PHYSICALLY_CHALLENGING_JOB]: 'Fyzicky náročná brigáda',
};

export const formatNumber = (intl: IntlShape, amount: number) => intl.formatNumber(amount, {
  style: 'decimal',
  minimumFractionDigits: amount % 1 ? 2 : 0,
  maximumFractionDigits: amount % 1 ? 2 : 0,
});
