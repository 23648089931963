export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum ApplicationState {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  SYSTEM_CANCELED = 'system_canceled',
  CANCELED_EARLY = 'canceled_early',
  CANCELED_LATE = 'canceled_late',
}

export const enum AttendanceResolution {
  UNEXCUSED_ABSENCE = 'unexcused_absence',
  ADMIN_EXCUSED_ABSENCE = 'admin_excused_absence',
  EXCUSED_ABSENCE = 'excused_absence',
  CANCEL = 'cancel',
  OK = 'ok',
  BREACH_OF_DISCIPLINE = 'breach_of_discipline',
}

export const enum DocumentType {
  ID_CARD = 'id_card',
  HEALTH_CARD = 'health_card',
  CRIMINAL_RECORD = 'criminal_record',
  DRIVING_LICENCE = 'driving_license',
  CONTRACT_DPP = 'contract_dpp',
  CONTRACT_DPC = 'contract_dpc',
  CONTRACT_HPP = 'contract_hpp',
  TYMBE_CONTRACT = 'tymbe_contract',
  CONTRACT_DPP_TEMPLATE = 'contract_dpp_template',
  CONTRACT_DPC_TEMPLATE = 'contract_dpc_template',
  CONTRACT_HPP_TEMPLATE = 'contract_hpp_template',
  DODP_TEMPLATE = 'dodp_template',
  PINKIE = 'pinkie',
  OTHER = 'other',
}

export const ContractDocumentTypes = [
  DocumentType.CONTRACT_DPP,
  DocumentType.CONTRACT_DPC,
  DocumentType.CONTRACT_HPP,
];

export const enum DocumentValidityStart {
  SHIFT_START = 'shift_start',
  ON_SIGN = 'on_sign',
}

export const enum DocumentValidityEnd {
  DAY_END = 'day',
  MONTH_END = 'month',
  YEAR_END = 'year',
}

export const enum DocumentValidityDurationUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum CompanyState {
  PENDING = 'pending',
  ACTIVE = 'active',
  BANNED = 'banned',
}

export const enum CompanyType {
  REGULAR = 'regular',
  CONTRACTOR = 'contractor',
  SUBCONTRACTOR = 'subcontractor',
}

export const enum CompanyFulfillmentType {
  MANDATE = 'mandate',
  SERVICE = 'service',
  AGENCY = 'agency',
}

export const enum SalaryAdvanceRestrictionStatus {
  MIN_ENABLED = 'min_enabled', // minimální nezabavitelná částka
  FORBIDDEN = 'forbidden', // zakázáno (0)
}

export const enum NoteType {
  NOTE = 'note',
  ORDER_DETAILS = 'order_details',
}

export const enum BranchofficeType {
  AREA = 'area',
  BRANCH = 'branch',
  DEPARTMENT = 'department',
  VACATION = 'vacation',
}

export const enum SurveyQuestionType {
  TEXT = 'text',
  LONG_TEXT = 'longtext',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DROPDOWN = 'dropdown',
}

export const enum PerkGroup {
  AGE = 'age',
  SEX = 'sex',
  QUALIFICATION = 'QUALIFICATION',
  NATIONALITY = 'nationality',
}

export const enum Perk {
  MAN_ONLY = 'man_only',
  WOMAN_ONLY = 'woman_only',
  ADULT = 'adult',
  EU_CITIZEN = 'eu_citizen',
  /** @deprecated */
  NON_EU_CITIZEN = 'non_eu_citizen',
  NON_UA_PROTECTED = 'non_ua_protected',
  COMPANY_JUNIOR = 'company_junior',
  COMPANY_SENIOR = 'company_senior',
  BRANCHOFFICE_SENIOR = 'branchoffice_senior',
  POSITION_SENIOR = 'position_senior',
}

export const enum ContactType {
  EMAIL = 'email',
  MOBILE_PHONE = 'mobile_phone',
  LANDLINE = 'landline',
  OTHER = 'other',
}

export const enum NotificationType {
  LAST_MINUTE_APPLICATION_CHANGE = 'last_min_app_change',
  PERSON_DOCUMENT_VALIDATED = 'person_document_validated',
  TYMBER_SHIFT_INVITE = 'tymber_shift_invite',
}

export const enum ValidateActionTypes {
  CALCULATE_MONTHLY_WITHHOLDING_TAX = 'calculateMonthlyWithholdingTax',
  EXCEED_MONTHLY_EARNINGS = 'exceedMonthlyEarnings',
  EXCEED_YEARLY_WORKTIME = 'exceedYearlyWorktime',
  IS_SHIFT_INTERSECTING = 'isShiftIntersecting',
}

export const enum PaymentConstantSymbol {
  CREDITS = '4316',
  REGULAR = '0138',
}

export const enum Roles {
  SUPER_ADMIN = 'super_admin',
  TYMBE_ADMIN = 'tymbe_admin',
  ADMIN = 'admin',
  TYMBE_COORDINATOR = 'tymbe_coordinator',
  COMPANY = 'company_admin',
  BRANCHOFFICE_MANAGER = 'branchoffice_manager',
  BRANCHOFFICE_SUPERVISOR = 'branchoffice_supervisor',
  SHIFT_SUPERVISOR = 'shift_supervisor',
  TYMBER = 'tymber',
}

export const enum AuthManagementActionTypes {
  VERIFY_SIGNUP = 'verifySignup',
  GET_USER_BY_TOKEN = 'getUserByToken',
  SEND_VERIFY_SIGNUP = 'sendVerifySignup',
  RESET_PASSWORD = 'resetPassword',
  SEND_RESET_PASSWORD = 'sendResetPassword',
  SEND_BANNED_SHIFTS_INFO = 'sendBannedShiftsInfo',
  VERIFY_PASSWORD = 'verifyPassword',
  SEND_APPLICATION_SYSTEM_CANCELED_INFO = 'sendApplicationSystemCanceledInfo',
}

export const enum JobClassificationType {
  TYMBE = 'tymbe',
}

export const enum PersonAccountStateEnum {
  ADMIN = 'admin',
  COMPANY = 'company',
  BAN = 'ban',
  UNVERIFIED_ACCOUNT = 'unverified_account',
  UNFINISHED_REGISTRATION = 'unfinished_registration',
  UNVERIFIED_PROOF_OF_IDENTITY = 'unverified_proof_of_identity',
  ACTIVE = 'active',
}

export const enum IntegrationEventEnum {
  SYNC = 'sync',
  QUEUE = 'queue',
  CREATE = 'create',
  UPDATE = 'update',
  REMOVE = 'remove',
  CHANGE = 'change',
}

export const enum IntegrationEntityEnum {
  SHIFT = 'shift',
  APPLICATION = 'application',
}

export const enum IntegrationSourceEnum {
  EXTERNAL = 'external', // from Ty-Zasilkovna
  INTERNAL = 'internal', // from Ty-Brigady
}

export const enum MailResult {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const enum SalaryLimitType {
  AVOID_TAXES = 'AVOID_TAXES',
  NONE = 'NONE',
}

export const enum PersonBanType {
  YOUNG = 'young',
  OLD = 'old',
}

export enum ForeignerOrigin {
  EU_CITIZEN = 'eu_citizen',
  EHP_CITIZEN = 'ehp_citizen',
  SWITZERLAND_CITIZEN = 'switzerland_citizen',
  GBR_CITIZEN = 'gbr_citizen',
  EU_FAMILY_CITIZEN = 'eu_family_citizen',
  TEMPORARY_PROTECTION_HOLDER_UKRAINE = 'temporary_protection_holder_ukraine',
  FOREIGNER_BY_98_LETTER_A = 'foreigner_by_98_letter_a',
  FOREIGNER_BY_98_LETTER_B = 'foreigner_by_98_letter_b',
  FOREIGNER_BY_98_LETTER_C = 'foreigner_by_98_letter_c',
  FOREIGNER_BY_98_LETTER_D = 'foreigner_by_98_letter_d',
  FOREIGNER_BY_98_LETTER_E = 'foreigner_by_98_letter_e',
  FOREIGNER_BY_98_LETTER_F = 'foreigner_by_98_letter_f',
  FOREIGNER_BY_98_LETTER_G = 'foreigner_by_98_letter_g',
  FOREIGNER_BY_98_LETTER_H = 'foreigner_by_98_letter_h',
  FOREIGNER_BY_98_LETTER_I = 'foreigner_by_98_letter_i',
  FOREIGNER_BY_98_LETTER_J = 'foreigner_by_98_letter_j',
  FOREIGNER_BY_98_LETTER_K = 'foreigner_by_98_letter_k',
  FOREIGNER_BY_98_LETTER_L = 'foreigner_by_98_letter_l',
  FOREIGNER_BY_98_LETTER_M = 'foreigner_by_98_letter_m',
  FOREIGNER_BY_98_LETTER_N = 'foreigner_by_98_letter_n',
  FOREIGNER_BY_98_LETTER_O = 'foreigner_by_98_letter_o',
  FOREIGNER_BY_98_LETTER_P = 'foreigner_by_98_letter_p',
  FOREIGNER_BY_98_LETTER_Q = 'foreigner_by_98_letter_q',
  FOREIGNER_BY_98_LETTER_R = 'foreigner_by_98_letter_r',
  FOREIGNER_BY_98_LETTER_S = 'foreigner_by_98_letter_s',
  FOREIGNER_BY_98_A = 'foreigner_by_98_a',
}

export const enum EmailSenderAddress {
  NOREPLY = 'noreply@tymbe.com',
  ADMIN = 'admin@tymbe.com',
}

export const enum VacationApprovedType {
  TAKE = 'take',
  GIVE = 'give',
}

export enum CountryCodes {
  AFG = 'AFG',
  ALA = 'ALA',
  ALB = 'ALB',
  DZA = 'DZA',
  ASM = 'ASM',
  VIR = 'VIR',
  AND = 'AND',
  AGO = 'AGO',
  AIA = 'AIA',
  ATA = 'ATA',
  ATG = 'ATG',
  ARG = 'ARG',
  ARM = 'ARM',
  ABW = 'ABW',
  AUS = 'AUS',
  AZE = 'AZE',
  BHS = 'BHS',
  BHR = 'BHR',
  BGD = 'BGD',
  BRB = 'BRB',
  BEL = 'BEL',
  BLZ = 'BLZ',
  BLR = 'BLR',
  BEN = 'BEN',
  BMU = 'BMU',
  BTN = 'BTN',
  BOL = 'BOL',
  BES = 'BES',
  BIH = 'BIH',
  BWA = 'BWA',
  BVT = 'BVT',
  BRA = 'BRA',
  IOT = 'IOT',
  VGB = 'VGB',
  BRN = 'BRN',
  BGR = 'BGR',
  BFA = 'BFA',
  BDI = 'BDI',
  COK = 'COK',
  CUW = 'CUW',
  TCD = 'TCD',
  MNE = 'MNE',
  CZE = 'CZE',
  CHN = 'CHN',
  DNK = 'DNK',
  COD = 'COD',
  DMA = 'DMA',
  DOM = 'DOM',
  DJI = 'DJI',
  EGY = 'EGY',
  ECU = 'ECU',
  ERI = 'ERI',
  EST = 'EST',
  ETH = 'ETH',
  FRO = 'FRO',
  FLK = 'FLK',
  FJI = 'FJI',
  PHL = 'PHL',
  FIN = 'FIN',
  FRA = 'FRA',
  GUF = 'GUF',
  ATF = 'ATF',
  PYF = 'PYF',
  GAB = 'GAB',
  GMB = 'GMB',
  GHA = 'GHA',
  GIB = 'GIB',
  GRD = 'GRD',
  GRL = 'GRL',
  GEO = 'GEO',
  GLP = 'GLP',
  GUM = 'GUM',
  GTM = 'GTM',
  GGY = 'GGY',
  GIN = 'GIN',
  GNB = 'GNB',
  GUY = 'GUY',
  HTI = 'HTI',
  HMD = 'HMD',
  HND = 'HND',
  HKG = 'HKG',
  CHL = 'CHL',
  HRV = 'HRV',
  IND = 'IND',
  IDN = 'IDN',
  IRQ = 'IRQ',
  IRN = 'IRN',
  IRL = 'IRL',
  ISL = 'ISL',
  ITA = 'ITA',
  ISR = 'ISR',
  JAM = 'JAM',
  JPN = 'JPN',
  YEM = 'YEM',
  JEY = 'JEY',
  ZAF = 'ZAF',
  SGS = 'SGS',
  SSD = 'SSD',
  JOR = 'JOR',
  CYM = 'CYM',
  KHM = 'KHM',
  CMR = 'CMR',
  CAN = 'CAN',
  CPV = 'CPV',
  QAT = 'QAT',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KIR = 'KIR',
  CCK = 'CCK',
  COL = 'COL',
  COM = 'COM',
  COG = 'COG',
  PRK = 'PRK',
  KOR = 'KOR',
  XXK = 'XXK',
  CRI = 'CRI',
  CUB = 'CUB',
  KWT = 'KWT',
  CYP = 'CYP',
  KGZ = 'KGZ',
  LAO = 'LAO',
  LSO = 'LSO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LIE = 'LIE',
  LTU = 'LTU',
  LVA = 'LVA',
  LUX = 'LUX',
  MAC = 'MAC',
  MDG = 'MDG',
  HUN = 'HUN',
  MKD = 'MKD',
  MYS = 'MYS',
  MWI = 'MWI',
  MDV = 'MDV',
  MLI = 'MLI',
  MLT = 'MLT',
  IMN = 'IMN',
  MAR = 'MAR',
  MHL = 'MHL',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MRT = 'MRT',
  MYT = 'MYT',
  UMI = 'UMI',
  MEX = 'MEX',
  FSM = 'FSM',
  MDA = 'MDA',
  MCO = 'MCO',
  MNG = 'MNG',
  MSR = 'MSR',
  MOZ = 'MOZ',
  MMR = 'MMR',
  NAM = 'NAM',
  NRU = 'NRU',
  DEU = 'DEU',
  NPL = 'NPL',
  NER = 'NER',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NFK = 'NFK',
  NOR = 'NOR',
  NCL = 'NCL',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PLW = 'PLW',
  PSE = 'PSE',
  PAN = 'PAN',
  PNG = 'PNG',
  PRY = 'PRY',
  PER = 'PER',
  PCN = 'PCN',
  CIV = 'CIV',
  POL = 'POL',
  PRI = 'PRI',
  PRT = 'PRT',
  AUT = 'AUT',
  REU = 'REU',
  GNQ = 'GNQ',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  GRC = 'GRC',
  SPM = 'SPM',
  SLV = 'SLV',
  WSM = 'WSM',
  SMR = 'SMR',
  SAU = 'SAU',
  SEN = 'SEN',
  MNP = 'MNP',
  SYC = 'SYC',
  SLE = 'SLE',
  SGP = 'SGP',
  SVK = 'SVK',
  SVN = 'SVN',
  SOM = 'SOM',
  ARE = 'ARE',
  USA = 'USA',
  SRB = 'SRB',
  CAF = 'CAF',
  SDN = 'SDN',
  SUR = 'SUR',
  SHN = 'SHN',
  LCA = 'LCA',
  BLM = 'BLM',
  KNA = 'KNA',
  MAF = 'MAF',
  SXM = 'SXM',
  STP = 'STP',
  VCT = 'VCT',
  SWZ = 'SWZ',
  SYR = 'SYR',
  SLB = 'SLB',
  ESP = 'ESP',
  SJM = 'SJM',
  LKA = 'LKA',
  SWE = 'SWE',
  CHE = 'CHE',
  TJK = 'TJK',
  TZA = 'TZA',
  THA = 'THA',
  TWN = 'TWN',
  TGO = 'TGO',
  TKL = 'TKL',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TKM = 'TKM',
  TCA = 'TCA',
  TUV = 'TUV',
  UGA = 'UGA',
  UKR = 'UKR',
  URY = 'URY',
  UZB = 'UZB',
  CXR = 'CXR',
  VUT = 'VUT',
  VAT = 'VAT',
  GBR = 'GBR',
  VEN = 'VEN',
  VNM = 'VNM',
  TLS = 'TLS',
  WLF = 'WLF',
  ZMB = 'ZMB',
  ESH = 'ESH',
  ZWE = 'ZWE',
}

export const validCzechCountries = [CountryCodes.CZE];
export const NON_EU_COUNTRIES = [
  CountryCodes.UKR,
  CountryCodes.RUS,
  CountryCodes.KAZ,
  CountryCodes.BLR,
  CountryCodes.MDA,
  CountryCodes.MNG,
  CountryCodes.SRB,
  CountryCodes.KGZ,
  CountryCodes.EGY,
  CountryCodes.XXK,
  CountryCodes.UZB,
  CountryCodes.MKD,
  CountryCodes.MLI,
];

export const EU_COUNTRIES = [
  CountryCodes.CZE,
  CountryCodes.SVK,
  CountryCodes.POL,
  CountryCodes.ROU,
  CountryCodes.HUN,
  CountryCodes.DEU,
  CountryCodes.ITA,
  CountryCodes.CYP,
  CountryCodes.HRV,
  CountryCodes.BGR,
];

export const enum ShiftType {
  REGULAR = 'regular',
  VACATION = 'vacation',
}

export const EEA_ONLY_COUNTRY_CODES = [CountryCodes.ISL, CountryCodes.LIE, CountryCodes.NOR];

export const EU_EEA_SWISS_COUNTRY_CODES = [
  ...EU_COUNTRIES,
  ...EEA_ONLY_COUNTRY_CODES,
  CountryCodes.CHE,
];

export const EuEeaSwitzCountryCode = Object.fromEntries(EU_EEA_SWISS_COUNTRY_CODES.map((code) => [code, code])) as {
  [TKey in typeof EU_EEA_SWISS_COUNTRY_CODES[number]]: TKey;
};
export type EuEeaSwitzCountryCode = typeof EuEeaSwitzCountryCode[keyof typeof EuEeaSwitzCountryCode];

export enum SurveyCategorySlug {
  referralSource = 'referral_source',
  tymbeAnketa = 'tymbe_anketa',
}
