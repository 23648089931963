import { Card } from '../components/Card';
import { CardContent } from '../components/Card/CardContent';
import { CardFooter } from '../components/Card/CardFooter';
import { CardHeaderCentered } from '../components/Card/CardHeader';
import { H1, H2, H5 } from '../components/Styles/BasicElements';
import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import api from '../services/api';
import moment from 'moment';
import { Toggleable } from '../components/Toggleable';
import { RingToggleIcon } from '../components/Ring';
import CircleIcon from '../icons/CircleIcon';
import { CzechHolidayCalculator } from '@tymbe/legislatives/czechia/CzechHolidayCalculator';

interface TrafficLightMap {
  [key: string]: string
}

interface TrafficLightEstimateMap {
  [key: string]: number[]
}

const TrafficLight = () => {
  const intl = useIntl();

  const { data: trafficLightData } = useQuery(['trafficLight'],
    async () => api.getTrafficLight(),
  );

  const trafficLightColour = trafficLightData?.data[0]?.value || 'green';

  const TrafficLightDescriptions: TrafficLightMap = {
    green: intl.formatMessage({
      defaultMessage: 'Vyplácíme 1. pracovní den',
      description: 'Uvozuje vysvětlivku semaforu - Vyplácíme ihned',
    }),
    orange: intl.formatMessage({
      defaultMessage: 'Vyplácíme do 5 pracovních dní',
      description: 'Uvozuje vysvětlivku semaforu - Vyplácíme s malým zpožděním',
    }),
    red: intl.formatMessage({
      defaultMessage: 'Vyplácíme do 10 pracovních dní',
      description: 'Uvozuje vysvětlivku semaforu - Vyplácíme s velkým zpožděním',
    })
  };

  const trafficLightColourSwitch = (trafficLightColour: string) => {
    switch (trafficLightColour) {
      case 'green':
        return '#B6CD1E';
      case 'orange':
        return '#EC8900';
      case 'red':
        return '#E84427';
      default:
        return '#B6CD1E';
    }
  };

  const TrafficLightEstimate: TrafficLightEstimateMap = {
    green: [1],
    orange: [2, 5],
    red : [6, 10],
  }

  const { data: unpaidWagesData } = useQuery(['unpaidWages'],
    async () => api.getUnpaidWages(),
  );

  const unpaidWages = unpaidWagesData?.data;
  const [expanded, changeExpanded] = React.useState(false);

  const toggle = () => {
    changeExpanded(!expanded)
  };

  const skipWeekendsAndHolidays = (date: string, estimatedPayOutEndDay: number) => {
    const startDate = moment(date);
    const endDate = startDate.clone().add(estimatedPayOutEndDay, 'days');
    const weekDays: string[] = [];

    for (const currentDate = startDate.clone(); currentDate.isSameOrBefore(endDate); currentDate.add(1, 'day')) {
      if (currentDate.day() === 6 || currentDate.day() === 0 || new CzechHolidayCalculator().isDayHoliday(currentDate)) {
        endDate.add(1, 'day');
      } else {
        weekDays.push(currentDate.format('DD.MM.YYYY'));
      }
    }
    return weekDays;
  };

  const setPayOutDates = (date: string, estimatedPayOutDays: number[]) => {
    const weekDays = skipWeekendsAndHolidays(date, estimatedPayOutDays[1]);

    return moment(weekDays[estimatedPayOutDays[0]], 'DD.MM.YYYY').format('DD.MM.') + ' - ' + weekDays[weekDays.length - 1];
  };

  return (
    <Card color={'gold'} onClick={toggle}>
      <CardHeaderCentered>
        <div className='flex items-center'>
        <RingToggleIcon toggled={expanded} />
          <H1>{intl.formatMessage({
          defaultMessage: 'Výplatní semafor',
          description: 'Nadpis sekce na strance výplat',
            })}</H1>
        </div>
      </CardHeaderCentered>
      <CardContent>
        <div className='flex justify-around items-center'>
          <H2 style={{ display: 'inline' }}>{intl.formatMessage({
              defaultMessage: 'Aktuální stav:',
              description: 'Aktuální stav',
            })}</H2>

            <span className="relative flex pr-2">
            <CircleIcon
              iconcolor={trafficLightColourSwitch(trafficLightColour)}
              className="animate-ping absolute inline-flex h-full w-full pr-1"
            />
            <CircleIcon
              iconcolor={trafficLightColourSwitch(trafficLightColour)}
              className="relative inline-flex"
            />
          </span>
        </div>
        <div className='flex justify-around items-center p-2'>
          <strong>{TrafficLightDescriptions[trafficLightColour]}</strong>
        </div>
        <hr />

        <Toggleable toggled={!!expanded}>
        {!unpaidWages?.length ? <div>{intl.formatMessage({
              defaultMessage: 'Žádné aktivní žádosti o platby.',
              description: 'Žádné aktivní žádosti o platby.',
            })}</div> :
          <div>
            <div className='flex justify-between pb-6'>
            <H5 style={{ display: 'inline' }}>{intl.formatMessage({
            defaultMessage: 'Žádosti o platby',
            description: 'Žádosti o platby',
          })}</H5>
              <H5 style={{ display: 'inline' }}>{intl.formatMessage({
                  defaultMessage: 'Výplatní termín',
                  description: 'Výplatní termín',
                })}</H5>
            </div>
            <div>{unpaidWages?.map((val) =>
              <div className='flex justify-between pb-5' key={val.id}>
                <div>
                  <div>{moment(val.paymentRequest?.created_at).format("DD.MM.YYYY")}</div>
                  <div>{val.company?.name}</div>
                </div>
                <div>
                  {val.paymentRequest?.created_at && (
                    <>
                      {TrafficLightEstimate[trafficLightColour].length > 1 ?
                      <div>
                        {setPayOutDates(val.paymentRequest?.created_at, TrafficLightEstimate[trafficLightColour])}
                      </div> :
                      <div>
                        {skipWeekendsAndHolidays(val.paymentRequest?.created_at, TrafficLightEstimate[trafficLightColour][0])[1]}
                      </div>}
                    </>
                  )}
                </div>
              </div>
            )}
            </div>
          </div>}
        </Toggleable>
      </CardContent>
      <CardFooter />
    </Card>
  )
}

export default TrafficLight
