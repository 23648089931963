import { connect } from 'react-redux';
import { compose } from 'redux';

import { invitationRejectRequest, invitationsRequest, moreInvitationsRequest } from '../actions/invitations';
import { vacationRequest } from '../actions/vacations';
import { Error } from '../components/Error';
import { InvitationList } from '../components/InvitationList';
import Spinner from '../components/Spinner';
import withError from '../components/withError';
import withFetch from '../components/withFetch';
import withLoading from '../components/withLoading';
import { componentsSelector, dataSelector, StoreState } from '../reducers';
import { invitationsSelector } from '../reducers/components';
import { dataVacationsSelector } from '../reducers/data';
import { DataStatus, STATUS_ERROR, STATUS_PENDING, STATUS_SUCCESS } from '../types/StoreUtils';
import { Ring } from '../types/Tymbe';

const selectInvitations = compose(invitationsSelector, componentsSelector);
const selectVacations = compose(dataVacationsSelector, dataSelector);

const InvitationContainer = connect(
  (state: StoreState) => {
    const inv = selectInvitations(state);
    const vac = selectVacations(state);
    // eslint-disable-next-line no-nested-ternary
    const status: DataStatus = [inv.status, vac.status].includes(STATUS_ERROR) ? STATUS_ERROR
      : [inv.status, vac.status].includes(STATUS_PENDING) ? STATUS_PENDING
        : STATUS_SUCCESS;
    return {
      status,
      loading: status === STATUS_PENDING,
      data: {},
      error: inv.error ?? vac.error,
      rings: inv.data ?? [],
      vacations: vac.data ?? [],
    };
  },
  (dispatch) => ({
    fetch: () => { dispatch(invitationsRequest()); dispatch(vacationRequest()); },
    revokeInvitation: (ringId: Ring['id']) => dispatch(invitationRejectRequest(ringId)),
    moreInvitations: (filters?: object) => dispatch(moreInvitationsRequest(filters)),
  }),
);

export default InvitationContainer(
  withFetch(
    withLoading(Spinner)(
      withError(Error)(
        InvitationList,
      ),
    ),
  ),
);
