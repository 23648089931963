import { Button } from './Forms/Button';
import { intl } from './I18n';
import { useModalError } from './Modal/ModalError';
import Spinner from './Spinner';
import { H4 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import TymberDocumentFile from '../containers/TymberDocumentFile';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';
import { ApiError, TymberDocument, TymberDocumentStates } from '../types/Tymbe';

const DocumentItem = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "title title title"
    "info . status"
    "list list list";
  padding: 5px 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
`;

const DocumentTitle = styled(H4)`
  grid-area: title;
`;

const DocumentInfo = styled.div`
  grid-area: info;
`;

const DocumentStatus = styled.div<{ state: TymberDocumentStates }>`
  font-size: 0.8rem;
  grid-area: status;
  text-align: right;
  font-weight: 900;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: ${({ state }) => getStateColor(state)};
  color: white;
  text-transform: uppercase;
`;

const DocumentList = styled.div`
  grid-area: list;
  margin-left: 5px;
`;

export type TymberDocumentItemProps = {
  document: TymberDocument;
  error: ApiError;
  loading: boolean;
  invalidateDocument: (id: TymberDocument['id']) => void;
};

const getStateColor = (state: TymberDocumentStates) => {
  switch (state) {
    case TymberDocumentStates.INVALIDATED:
    case TymberDocumentStates.REJECTED:
      return 'red';
    case TymberDocumentStates.NOT_APPROVED:
      return 'lightslategray';
    case TymberDocumentStates.APPROVED:
      return 'green';
    default:
      return 'black';
  }
};

const getStateName = (state: TymberDocumentStates) => {
  switch (state) {
    case TymberDocumentStates.APPROVED:
      return intl.formatMessage({
        defaultMessage: 'Ověřeno',
        description: 'Stav dokumentu',
      });
    case TymberDocumentStates.INVALIDATED:
      return intl.formatMessage({
        defaultMessage: 'Zneplatněno',
        description: 'Stav dokumentu',
      });
    case TymberDocumentStates.NOT_APPROVED:
      return intl.formatMessage({
        defaultMessage: 'Čeká na ověření',
        description: 'Stav dokumentu',
      });
    case TymberDocumentStates.REJECTED:
      return intl.formatMessage({
        defaultMessage: 'Zamítnuto',
        description: 'Stav dokumentu',
      });
    default:
      return intl.formatMessage({
        defaultMessage: 'Neznámé',
        description: 'Stav dokumentu',
      });
  }
};

const getDateOrInfinite = (date: string) => date
  ? intl.formatDate(moment(date).toDate(), { day: '2-digit', month: '2-digit', year: 'numeric' })
  : 'nekonečno';

export const TymberDocumentItem = ({ document, invalidateDocument, error, loading }: TymberDocumentItemProps) => {
  // tslint:disable-next-line:no-shadowed-variable we need to use this name because of automation
  const intl = useIntl();
  const showError = useModalError();
  if (error) {
    showError(intl.formatMessage({
      defaultMessage: 'Doklad nelze smazat',
      description: 'Text chyby',
    }));
  }
  return (
    <DocumentItem>
      {loading && <Spinner position={'absolute'} />}
      <DocumentTitle>
        {document.perk.title}
        <Button
          onClick={() => invalidateDocument(document.id)}
          style={{ float: 'right', padding: '.5rem', lineHeight: 0 }}
        >
          <Icon id={'false'} size={'1.5rem'} color={'white'} background={'none'} />
        </Button>
      </DocumentTitle>
      <DocumentInfo>
        {intl.formatMessage(
          {
            defaultMessage: 'platnost do: {valid_until}',
            description: 'Platnost dokladu, například občanského průkazu. Obsahuje datum nebo "nekonečno".',
          },
          { valid_until: getDateOrInfinite(document.time.end) },
        )}
      </DocumentInfo>
      <DocumentList>
        {document.parts.map(part => <TymberDocumentFile key={part.id} id={part.id} />)}
      </DocumentList>
      <DocumentStatus state={document.state}>{getStateName(document.state)}</DocumentStatus>
    </DocumentItem>
  );
};
