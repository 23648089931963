import { tymberDataRequest } from '../actions/tymberData';
import LocaleSwitcher from './LocaleSwitcher';
import MenuIcon from './Menu/MenuIcon';
import MenuItem from './Menu/MenuItem';
import MenuSpacer from './Menu/MenuSpacer';
import { withFetchNew } from './withFetch';
import { withLoaderNew } from './withLoading';
import { UserMenu } from '../containers/UserMenu';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { tymberSelector } from '../reducers';
import { styled } from '../styledComponents';
import { TymberStates } from '../types/Tymbe';

const Pill = styled.span`
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 22%;
    right: -5px;
    display: flex;
    border-radius: 1.5rem;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    text-align: center;
`;

const MainNavigation: React.FC<{ loading?: boolean, onClick?: (e: React.MouseEvent) => void }> =
  ({ loading, ...props }) => {
    const intl = useIntl();
    const { state, claimed, invitations } = useSelector(tymberSelector).data || {};
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(
      () => {
        dispatch(tymberDataRequest());
      },
      [dispatch, claimed, location.key],
    );

    const isActive = state === TymberStates.ACTIVE;
    return (
      <>
        {isActive && <MenuItem to={'/ring'} {...props}><MenuIcon id={'ring'} />{intl.formatMessage({
          defaultMessage: 'Ring',
          description: 'Odkaz navigačního menu',
        })}</MenuItem>}
        {isActive && <MenuItem to={'/invitation'} {...props}><MenuIcon id={'invitations'} />
          {intl.formatMessage({
            defaultMessage: 'Pozvánky',
            description: 'Odkaz navigačního menu',
          })}
          {!!invitations && <Pill>{invitations}</Pill>}
        </MenuItem>}
        {isActive && <MenuItem to={'/before-fight'} {...props}>
          <MenuIcon id={'beforeFight'} />
          {intl.formatMessage({
            defaultMessage: 'Moje brigády',
            description: 'Odkaz navigačního menu',
          })}
          {!!claimed && <Pill>{claimed}</Pill>}
        </MenuItem>}
        <MenuItem to={'/finished'} {...props}><MenuIcon id={'finished'} />{intl.formatMessage({
          defaultMessage: 'Odpracováno',
          description: 'Odkaz navigačního menu',
        })}</MenuItem>
        <MenuItem to={'/payment'} {...props}><MenuIcon id={'payments'} />{intl.formatMessage({
          defaultMessage: 'Výplata',
          description: 'Odkaz navigačního menu',
        })}</MenuItem>
        <MenuItem to={'/locker'} {...props}><MenuIcon id={'locker'} />{intl.formatMessage({
          defaultMessage: 'Šatna',
          description: 'Odkaz navigačního menu',
        })}</MenuItem>
        <MenuSpacer style={{ minWidth: '2rem' }} {...props} />
        <LocaleSwitcher {...props} />
        <UserMenu {...props} />
      </>
    );
  };

const MainNavigationConnected = withFetchNew(tymberSelector, tymberDataRequest)(
  withLoaderNew(tymberSelector, '', true)(
    MainNavigation,
  ),
);

export { MainNavigationConnected as MainNavigation };
