import { Form, FormState } from 'informed';
import { useState } from 'react';

import Modal from '.';
import { TestJson, TestQuestion } from '../../types/Tymbe';
import { Card } from '../Card';
import { CardContent } from '../Card/CardContent';
import { CardFooter } from '../Card/CardFooter';
import { CardHeader, CardHeaderCentered } from '../Card/CardHeader';
import { Button } from '../Forms/Button';
import ColoredRadioGroup from '../Forms/ColoredRadioGroup';
import { H2, H3 } from '../Styles/BasicElements';

type TestModalProps = {
  openModal: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  testPassed: {
    status: boolean;
    points: string;
  },
  setTestPassed: React.Dispatch<React.SetStateAction<{
    status: boolean;
    points: string;
  }>>,
  evaluation: string,
  setEvaluation: React.Dispatch<React.SetStateAction<string>>,
  testJson: TestJson,
};

const TestModal = ({
  openModal,
  setOpenModal,
  setTestPassed, testJson, testPassed, evaluation, setEvaluation,
}: TestModalProps) => {
  const [correctAnswers, setCorrectAnswers] = useState(0);

  const onSubmit = (formState: FormState) => {
    let correct: TestQuestion[] = [];
    Object.values(formState.values).forEach((answer, index) => {
      if (answer === testJson.testQuestions[index].correct) {
        correct.push(testJson.testQuestions[index]);
      }
    });
    setCorrectAnswers(correct.length);
    if (correct.length >= testJson.evaluation.passed_excellent) {
      setEvaluation(`${correct.length}/${testJson.testQuestions.length} prospěl výborně`);
      setTestPassed({ status: true, points: `${correct.length}/${testJson.testQuestions.length}` });
    } else if (correct.length >= testJson.evaluation.passed) {
      setEvaluation(`${correct.length}/${testJson.testQuestions.length} prospěl`);
      setTestPassed({ status: true, points: `${correct.length}/${testJson.testQuestions.length}` });
    } else {
      correct = [];
      setEvaluation('neprospěl');
    }
  };

  return (
    <Modal open={openModal}>
      {!testPassed.status ? (
        <Form onSubmit={(formState) => onSubmit(formState)} validateOn="submit">
          {/* eslint-disable-next-line react/no-unused-prop-types */}
          {({ formState }: { formState: FormState }) => (
            <Card>
              <CardHeaderCentered><H2>BOZP Test</H2></CardHeaderCentered>
              <CardContent>
                {testJson.testQuestions.map((item, index) => (
                  <div>
                    <ColoredRadioGroup id={`${index}`} name={`answer${index}`} label={`${index + 1}. ${item.question}`} options={item.answers} correctAnswer={item.correct} />
                  </div>
                ))}
              </CardContent>
              <CardFooter>
                <div className="w-full flex">
                  <div>
                    <Button
                      type="submit"
                      disabled={Object.keys(formState.values).length !== testJson.testQuestions.length}
                    >Potvrdit
                    </Button>
                    <Button background="secondary" color="white" onClick={() => setOpenModal(false)}>Zavřít</Button>
                  </div>
                  <div className="ml-4 flex items-end text-xl font-bold">
                    {evaluation === 'neprospěl' && <span>Body: {correctAnswers}/{testJson.testQuestions.length}</span>}
                  </div>
                </div>
              </CardFooter>
            </Card>
          )}
        </Form>
      ) : (
        <Card>
          <CardHeader><H2>Test jste splnili</H2></CardHeader>
          <CardContent>
            <H3>Body: {testPassed.points}</H3>
          </CardContent>
          <CardFooter>
            <div className="w-full flex justify-end">
              <Button background="secondary" color="white" onClick={() => setOpenModal(false)}>Zavřít</Button>
            </div>
          </CardFooter>
        </Card>
      )}
    </Modal>
  );
};

export default TestModal;
