import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

import { StoreState } from '../../reducers';
import { Card } from '../Card';
import { CardContent } from '../Card/CardContent';
import { CardFooter } from '../Card/CardFooter';
import { CardHeader } from '../Card/CardHeader';
import { Button } from '../Forms/Button';
import Modal from '../Modal';
import { H2 } from '../Styles/BasicElements';

const cookies = new Cookies();

const RingEmptyPopup = () => {
  const tymberId = useSelector((state: StoreState) => state.auth.id);
  const [open, setOpen] = useState(Number(cookies.get('ringEmptyPopup')) !== tymberId);
  const intl = useIntl();

  const onDismiss = () => {
    cookies.set('ringEmptyPopup', tymberId, { path: '/', expires: moment().add(1, 'day').toDate() });
    setOpen(false);
  };

  return (
    <Modal open={open}>
      <Card>
        <CardHeader>
          <H2>{intl.formatMessage({
            defaultMessage: 'Ahoj, Tymbere!',
            description: 'Nadpis informačního modalu na stránce ringu',
          })}
          </H2>
        </CardHeader>
        <CardContent>
          {intl.formatMessage({
            defaultMessage: '<p>Vítáme tě v ringu brigád, kde se věci dějí tak rychle, že sis možná ani nestihl přihlásit brigádu. Možná jsou právě teď všechna místa obsazená (je to tady jak s lístky na koncert Rolling Stones – buď jsi rychlejší, nebo prostě sedíš doma).</p>'
            + '<p>Ale nebuď smutný! Protože jsme kamarádi, házíme ti podpultové info: <strong>Ring začíná ožívat vždycky ke konci týdne</strong>. To je ten moment, kdy vedoucí začínají hledat lidi na příští týden. Takže hlavu vzhůru, oči na stopkách, tvá šance přijde! </p>',
            description: 'Text informačního modalu, který se zorazuje jednou za 24 hodin na stránce ringu',
          })}

        </CardContent>
        <CardFooter>
          <Button onClick={onDismiss}>Zavřít</Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

export default RingEmptyPopup;
