import { saveTymberDOcumentCancel, saveTymberDocumentRequest } from '../actions/tymberDocuments';
import { AxiosRequestConfig } from 'axios';
import { AddTymberDocument } from '../components/AddTymberDocument';
import withMapData from '../components/withMapData';
import { connect } from 'react-redux';
import { componentsSelector, StoreState } from '../reducers';
import { addTymberDocumentSelector } from '../reducers/components';
import { compose } from 'redux';
import { STATUS_PENDING } from '../types/StoreUtils';
import { SaveTymberDocumentDataRequest } from '../types/Tymbe';

const selectAddTymberDocument = compose(addTymberDocumentSelector, componentsSelector);

const AddTymberDocumentConnect = connect(
  (state: StoreState) => selectAddTymberDocument(state),
  dispatch => ({
    saveDocument: (data: SaveTymberDocumentDataRequest, config: AxiosRequestConfig) =>
      dispatch(saveTymberDocumentRequest(data, config)),
    cancelSave: () => dispatch(saveTymberDOcumentCancel()),
  }),
);

export default AddTymberDocumentConnect(
  withMapData(
    (_data, status, error) => ({
      loading: status === STATUS_PENDING,
      error,
    }),
  )(AddTymberDocument));
