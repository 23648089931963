import { CzechSalaryTaxAdjustmentType } from '@tymbe/legislatives/czechia/CzechSalaryTaxAdjustmentType';
import { CzechSalaryTaxType } from '@tymbe/legislatives/czechia/CzechSalaryTaxType';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';

const messages = defineMessages<string, MessageDescriptor>({
  [CzechSalaryTaxType.SrazkovaDan]: {
    defaultMessage: 'Srážková daň',
    description: 'Název daně, použito v detailu výplat.',
  },
  [`${CzechSalaryTaxType.SrazkovaDan}_footnote`]: {
    defaultMessage: 'Uplatni slevu na dani, pokud ji neuplatňuješ v tomto měsíci u jiného zaměstnavatele. Návod jak na to najdeš v našem FAQu na webu <a>Jak si uplatnit slevu na dani</a>.',
    description: 'Text poznámky k dani, použito v detailu výplat.',
  },

  [CzechSalaryTaxType.ZalohovaDan]: {
    defaultMessage: 'Zálohová daň',
    description: 'Název daně, použito v detailu výplat.',
  },
  [`${CzechSalaryTaxType.ZalohovaDan}_footnote`]: {
    defaultMessage: 'Uplatni slevu na dani, pokud ji neuplatňuješ v tomto měsíci u jiného zaměstnavatele. Návod jak na to najdeš v našem FAQu na webu <a>Jak si uplatnit slevu na dani</a>.',
    description: 'Text poznámky k dani, použito v detailu výplat.',
  },

  [CzechSalaryTaxType.SocialniPojisteni]: {
    defaultMessage: 'Sociální pojištění',
    description: 'Název daně, použito v detailu výplat.',
  },

  [CzechSalaryTaxType.ZdravotniPojisteni]: {
    defaultMessage: 'Zdravotní pojištění',
    description: 'Název daně, použito v detailu výplat.',
  },

  [CzechSalaryTaxAdjustmentType.DoplatekNaMinimalniOdvodZdravotnihoPojisteni]: {
    defaultMessage: 'Doplatek na minimální odvod zdravotního pojištění',
    description: 'Název doplatku k dani, použito v detailu výplat.',
  },
  [`${CzechSalaryTaxAdjustmentType.DoplatekNaMinimalniOdvodZdravotnihoPojisteni}_footnote`]: {
    defaultMessage: 'Nahrej některý z potřebných dokladů a získej přístup k této části Tvé odměny. <a>Za koho platí pojistné stát</a>',
    description: 'Text poznámky k dani, použito v detailu výplat.',
  },

  [CzechSalaryTaxAdjustmentType.SlevaNaPoplatnika]: {
    defaultMessage: 'Sleva na poplatníka',
    description: 'Název slevy na dani, použito v detailu výplat.',
  },

  [CzechSalaryTaxAdjustmentType.SlevaNaStudenta]: {
    defaultMessage: 'Sleva na studenta',
    description: 'Název slevy na dani, použito v detailu výplat.',
  },

  [CzechSalaryTaxAdjustmentType.SlevaProInvalidniDuchodce]: {
    defaultMessage: 'Sleva pro invalidní důchodce',
    description: 'Název slevy na dani, použito v detailu výplat.',
  },

  [CzechSalaryTaxAdjustmentType.SlevaProZTPP]: {
    defaultMessage: 'Sleva pro ZTP/P',
    description: 'Název slevy na dani, použito v detailu výplat.',
  },

  [PaySupplementType.Holiday]: {
    defaultMessage: 'Svátky',
    description: 'Název příplatku, použito v detailu výplat.',
  },

  [PaySupplementType.Night]: {
    defaultMessage: 'Noční',
    description: 'Název příplatku, použito v detailu výplat.',
  },

  [PaySupplementType.Weekend]: {
    defaultMessage: 'Víkendy',
    description: 'Název příplatku, použito v detailu výplat.',
  },

  defaultTaxType: {
    defaultMessage: 'Daň',
    description: 'Obecný název daně, použito v detailu výplat.',
  },
  defaultTaxAdjustmentType: {
    defaultMessage: 'Sleva/doplatek',
    description: 'Obecný název slevy/doplatku k dani, použito v detailu výplat.',
  },
});
export default messages;

export const getTaxTranslation = (id: CzechSalaryTaxType, suffix?: 'footnote' | 'linkText') => {
  const key = suffix ? `${id}_${suffix}` : id;
  const trans = messages[key];

  if (suffix) return trans || null;
  return trans || messages.defaultTaxType;
};

export const getTaxAdjustmentTranslation = (id: CzechSalaryTaxAdjustmentType, suffix?: 'footnote' | 'linkText') => {
  const key = suffix ? `${id}_${suffix}` : id;
  const trans = messages[key];

  if (suffix) return trans || null;
  return trans || messages.defaultTaxAdjustmentType;
};

export const getPaySupplementTranslation = (type: PaySupplementType) => {
  const trans = messages[type];

  return trans || messages.defaultTaxAdjustmentType;
}
