import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { Form, FormState } from 'informed';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeaderCentered } from './Card/CardHeader';
import { Error } from './Error';
import { Button } from './Forms/Button';
import DatePicker from './Forms/DatePicker';
import { FormItem } from './Forms/FormItem';
import {
  Input as HTMLInput,
  LabeledInput,
} from './Forms/LabeledInput';
import { ProgressBar } from './ProgressBar';
import SelectDocumentType from './SelectDocumentType';
import Spinner from './Spinner';
import { H2 } from './Styles/BasicElements';
import api from '../services/api';
import { styled } from '../styledComponents';
import { MAX_FILE_SIZE } from '../types/consts';
import { ApiError, Perk, SaveTymberDocumentDataRequest } from '../types/Tymbe';

export type AddTymberDocumentProps = {
  saveDocument: (data: SaveTymberDocumentDataRequest, config: AxiosRequestConfig) => void;
  onCancel?: () => void;
  onSave?: () => void;
  loading: boolean;
  error: ApiError;
  cancelSave: () => void;
};

const Overlay = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

type FormData = {
  did: string;
  document_type_id: Perk['id'];
  from: string;
  to: string;
};

const dateFormat = 'DD. MM. YYYY';
export const AddTymberDocument =
  ({ onCancel, saveDocument, cancelSave, loading, onSave, error }: AddTymberDocumentProps) => {
    const intl = useIntl();
    const [files, setFiles] = useState<File[]>([]);
    const [progress, setProgress] = useState(0);
    const { data } = useQuery(
      ['documentTypes'],
      async () => api.getDocumentTypes(),
    );
    const documentTypes = data?.data ?? [];

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = e?.target?.files?.[0];
      if (!file) return;

      if (file.size > MAX_FILE_SIZE) {
        return;
      }

      const newFiles = [...files, file];
      setFiles(newFiles);
    };

    const onFileRemove = (field: number) => () => {
      files.splice(field, 1);
      setFiles([...files]);
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      const addFiles: File[] = [];
      if (e.dataTransfer?.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
          if (e.dataTransfer.items[i].kind === 'file') {
            const file = e.dataTransfer.items[i].getAsFile() as File;
            if (file && file.size <= MAX_FILE_SIZE) {
              addFiles.push(file);
            }
          }
        }
      } else if (e.dataTransfer?.files) {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < e.dataTransfer?.files.length || 0; i += 1) {
          if (e.dataTransfer.files[i].size <= MAX_FILE_SIZE) {
            addFiles.push(e.dataTransfer.files[i]);
          }
        }
      }
      // newFiles.length > 3 && ;
      setFiles((prev) => {
        const newFiles = [...prev, ...addFiles];
        newFiles.splice(3);
        return newFiles;
      });
    };

    const handleDragOver = (e: Event) => {
      e.preventDefault();
    };
    useEffect(
      () => {
        document.addEventListener('drop', handleDrop);
        document.addEventListener('dragover', handleDragOver);
        return () => document.removeEventListener('drop', handleDrop);
      },
      [],
    );

    const onProgress = (e: AxiosProgressEvent) => {
      const percentCompleted = (e.loaded * 100) / e.total!;
      console.log(e.loaded, percentCompleted);
      setProgress(percentCompleted);
    };

    const hideOverlay = () => setProgress(0);

    const validateDateIsAfter = (valueFrom: string | null, valueTo: string | null): string | undefined => {
      const dateFrom = valueFrom ? moment(valueFrom, dateFormat) : null;
      const dateTo = valueTo ? moment(valueTo, dateFormat) : null;
      if (dateFrom && dateTo && dateFrom.isAfter(dateTo)) {
        return intl.formatMessage(
          {
            defaultMessage: 'Platnost od nesmí překročit platnost do',
            description: 'Chyba při nesprávném pořadí dat',
          },
        );
      }

      return undefined;
    };

    const handleSubmit = (formaState: FormState) => {
      const formData = formaState.values as FormData;
      const dateFrom = formData.from ? moment(formData.from, dateFormat).toISOString() : null;
      const dateTo = formData.to ? moment(formData.to, dateFormat).toISOString() : null;

      const documentData = {
        ...formData,
        did: formData.did ?? null,
        from: dateFrom,
        to: dateTo,
        files,
      };
      saveDocument(documentData, { onUploadProgress: onProgress });
    };

    return (
      <Form onSubmit={handleSubmit}>
        <Card>
          <CardHeaderCentered>
            <H2>
              {intl.formatMessage({
                defaultMessage: 'Přidat doklad',
                description: 'Nadpis sekce pro přidání nového dokladu',
              })}
            </H2>
          </CardHeaderCentered>
          <CardContent>
            <SelectDocumentType
              id="doklad_perkId"
              name="document_type_id"
              documentTypes={documentTypes}
              label={intl.formatMessage({
                defaultMessage: 'Typ dokladu',
                description: 'Název drop-down seznamu typů dokladů',
              })}
              required
            />
            <LabeledInput
              id="doklad_documentId"
              name="did"
              defaultValue={null}
              label={intl.formatMessage({
                defaultMessage: 'Číslo dokladu',
                description: 'Název formulářového pole pro zadání čísla dokladu',
              })}
            />
            <DatePicker
              name="from"
              id="doklad_from"
              defaultValue={null}
              label={intl.formatMessage({
                defaultMessage: 'Platnost od',
                description: 'Nazev formulářového pole pro zadaní datumu platnosti dokladu',
              })}
              formatDate={dateFormat}
              autoComplete="off"
              locale="cs"
            />
            <DatePicker
              name="to"
              id="doklad_to"
              defaultValue={null}
              label={intl.formatMessage({
                defaultMessage: 'Platnost do',
                description: 'Nazev formulářového pole pro zadaní datumu platnosti dokladu',
              })}
              formatDate={dateFormat}
              validate={(value, values) => validateDateIsAfter(values.from as string, value as string)}
              autoComplete="off"
              locale="cs"
            />
            {files.map((file, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <FormItem key={i}>
                <p>{file.name}</p>
                <Button onClick={onFileRemove(i)} type="button">X</Button>
              </FormItem>
            ))}
            {files.length < 3 && (
              <FormItem>
                <HTMLInput type="file" onChange={onFileChange} value="" />
              </FormItem>
            )}
            {/* TODO Add i11n */}
            <p>
              Souhlasím s odesláním a <a className="text-primary" href="https://tymbe.cz/pravidla-zpracovani-osobnich-udaju.html">zpracováním</a> nahraného dokladu.
            </p>
            {(loading || progress === 100) && (
              <Overlay>
                {loading && <ProgressBar end={100} position={progress} />}
                {loading && <Spinner position="static" />}
                {!loading && !error && (
                  <H2 style={{ textAlign: 'center' }}>{intl.formatMessage({
                    defaultMessage: 'Doklad úspěšně uložen',
                    description: 'Nadpis informačního okna',
                  })}
                  </H2>
                )}
                {!loading && error && <Error error={error} onClose={hideOverlay} />}
              </Overlay>
            )}
          </CardContent>
          <CardFooter>
            {!loading && progress !== 100 && (
              <Button onClick={onCancel} type="button">{intl.formatMessage({
                defaultMessage: 'Zrušit',
                description: 'Tlačítko pro zrušení nahrávání nového dokladu.',
              })}
              </Button>
            )}
            {loading && progress !== 100 && (
              <Button onClick={cancelSave} type="button">{intl.formatMessage({
                defaultMessage: 'Zrušit nahrávání',
                description: 'Tlačítko pro přerušení probíhajícího nahrávání nového dokladu.',
              })}
              </Button>
            )}
            {!loading && progress !== 100 && (
              <Button type="submit" disabled={loading}>{intl.formatMessage({
                defaultMessage: 'Uložit',
                description: 'Tlačítko pro nahrávání vybranáho dokladu',
              })}
              </Button>
            )}
            {!loading && progress === 100 && (
              <Button type="button" onClick={onSave}>{intl.formatMessage({
                defaultMessage: 'Zavřít',
                description: 'Tlačítko zavírající sekci pro přidání nového dokladu.',
              })}
              </Button>
            )}
          </CardFooter>
        </Card>
      </Form>
    );
  };
