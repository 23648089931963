import moment from 'moment-timezone';
import {
  Address,
  CompanyRef,
  Credit,
  Genders,
  // Liability,
  getPerkNameById,
  Perk,
  Perks,
  RegistrationData,
  Ring,
  RingDocument,
  RingDocumentSigned,
  Survey,
  SurveyQuestion,
  SurveyQuestionState,
  SurveyQuestionType as TSurveyQuestionType,
  Tymber,
  TymberDocument,
  TymberDocumentStates,
  TymberPerk,
  TymberStates,
} from '../../types/Tymbe';
import { AddressData } from '@tymbe/schema/address.interface';
import { ApplicationData } from '@tymbe/schema/application.interface';
import { AttendanceData } from '@tymbe/schema/attendance.interface';
import { CompanyData } from '@tymbe/schema/company.interface';
import { DocumentTypeData } from '@tymbe/schema/document-type.interface';
import { LoginData } from '@tymbe/schema/login.interface';
import { PersonDocumentData } from '@tymbe/schema/person-document.interface';
// import { PersonLiabilityData } from '@tymbe/schema/person-liability.interface';
import { PerkData } from '@tymbe/schema/perk.interface';
import { ShiftData } from '@tymbe/schema/shift.interface';
import { SurveyData } from '@tymbe/schema/survey.interface';
import { SurveyQuestionData } from '@tymbe/schema/survey-question.interface';
import { DocumentType, Gender, SurveyQuestionType } from '@tymbe/schema/enums';
import { Primitive } from 'utility-types';

export const calculateWorkTime = (startTime: string, endTime: string) => {
  const hours = moment(endTime).diff(startTime, 'hours', true);
  return hours - ~~(hours / 6.5) * 0.5;
};

export const companyDataToCompanyRef = (companyData?: CompanyData): CompanyRef => ({
  id: companyData?.id || 0,
  name: companyData?.name || 'Bez názvu',
  oz: null,
  logo: companyData?.logo || null,
});

export const getAddress = (address?: Partial<AddressData>) => {
  const str = address?.addressline1?.split(' ');
  const [strNumber] = str?.splice(-1, 1) || [];
  return {
    street: [str?.join(' ') || '', strNumber],
    city: address?.locality || '',
    country: address?.country || null,
    zip: address?.zip || '',
  } as Address;
};

export const genderToGender = (g: Gender | null) => {
  switch (g) {
    case Gender.FEMALE:
      return Genders.WOMAN;
    default:
    case Gender.MALE:
      return Genders.MAN;
  }
};

export const personDocumentToDocument = (shiftId: number, pd?: PersonDocumentData): RingDocumentSigned | undefined => {
  if (!pd) {
    return undefined;
  }
  return {
    id: pd.id,
    documentId: pd.document_type_id,
    ringId: shiftId,
    time: {
      start: pd.valid_from!,
      end: pd.valid_to!,
    },
    tymbe: false,
  };
};

// Really quick rewrite, so I kept the old implementation for a reference
// can be deleted after release

// const getTymberState = (personDocument: Pick<PersonDocumentData, 'reviewed_at' | 'valid_from' | 'valid_to'>[]) => {
//   let state = TymberStates.REGISTRATION;
//   if (personDocument?.length > 0) state = TymberStates.NO_VALID_ID;
//   const hasValidIdCard = personDocument?.some(
//     (item) =>
//       item.reviewed_at !== null
//       && moment(item.valid_from || undefined).isSameOrBefore(undefined, 'day')
//       && moment(item.valid_to || undefined).isSameOrAfter(undefined, 'day'),
//   );
//   if (hasValidIdCard) state = TymberStates.ACTIVE;

//   return state;
// };

// TODO: just copy pasta from Admin, merge later
export const enum PersonAccountStateEnum {
  // ADMIN = 'admin',
  // COMPANY = 'company',
  BAN = 'ban',
  UNVERIFIED_ACCOUNT = 'unverified_account',
  UNFINISHED_REGISTRATION = 'unfinished_registration',
  UNVERIFIED_PROOF_OF_IDENTITY = 'unverified_proof_of_identity',
  ACTIVE = 'active',
}

// @ts-ignore
const getTymberState = (accountState) => {
  switch (accountState) {
    case PersonAccountStateEnum.ACTIVE:
      return TymberStates.ACTIVE;

    case PersonAccountStateEnum.UNVERIFIED_PROOF_OF_IDENTITY:
      return TymberStates.NO_VALID_ID;

    case PersonAccountStateEnum.BAN:
      return TymberStates.BANNED;

    case PersonAccountStateEnum.UNFINISHED_REGISTRATION:
      return TymberStates.POST_REGISTRATION;

    case PersonAccountStateEnum.UNVERIFIED_ACCOUNT:
    default:
      return TymberStates.REGISTRATION;
  }
}

export const loginToTymber = (login: LoginData): Tymber => {
  const perks = login.person?.personDocument?.map((doc) => personDocumentToTymberPerk(doc));
  const [idCard] = login.person?.personDocument?.filter((x) => x.documentType?.type === DocumentType.ID_CARD) || [];

  const accountState = login.person?.accountState?.account_state;
  return {
    id: login.person_id,
    prefixName: login.person?.prefix_name || '',
    sureName: login.person?.first_name || '???',
    familyName: login.person?.last_name || '???',
    suffixName: login.person?.suffix_name || '',
    email: login.username,
    phone: login.person?.contact?.find(
      (x) => x && x.type === 'mobile_phone',
    )?.value || '',
    gender: genderToGender(login.person?.personData?.gender || null),
    birthDate: login.person?.personData?.birthdate || '',
    idCardNumber: idCard?.did || '',
    idCardValidTo: idCard?.valid_to || '',
    address: getAddress(login.person?.personData?.permanentAddress),
    contactAddress: getAddress(login.person?.personData?.contactAddress),
    nationality: login.person?.personData?.nationality || '',
    perks: perks ?? [],
    bankAccountNumber: login.person?.personData?.bank_account?.split('/')?.[0] || '',
    bankNumber: login.person?.personData?.bank_account?.split('/')?.[1] || '',
    paymentNote: login.person?.personData?.payment_note || '',
    // state: login.person?.personDocument ? getTymberState(login.person.personDocument) : TymberStates.REGISTRATION,
    state: accountState ? getTymberState(accountState) : TymberStates.REGISTRATION,
    shirtSize: 'xxl',
    shoesSize: '30',
    glasses: true,
    foreignerOrigin: login.person?.personData?.foreigner_origin,
    email_notification_allowed: login.person?.personData?.email_notification_allowed || false,
  }};

export const loginToRegistration = (login: LoginData): RegistrationData => ({
  sureName: login.person?.first_name || '???',
  familyName: login.person?.last_name || '???',
  idCardNumber: login.person?.personData?.pin?.replace(/\//g, '') || '',
  bankAccountNumber: login.person?.personData?.bank_account?.split('/')?.[0] || '',
  bankNumber: login.person?.personData?.bank_account?.split('/')?.[1] || '',
  paymentNote: login.person?.personData?.payment_note || '',
  idExpirationDate: '2025-12-20', // TODO
  birthDate: login.person?.personData?.birthdate || '',
  gender: genderToGender(login.person?.personData?.gender || null),
  country: login.person?.personData?.nationality || '',
});

export enum PerkDataEnum {
  ADULT = 'adult',
  BRANCHOFFICE_SENIOR = 'branchoffice_senior',
  COMPANY_SENIOR = 'company_senior',
  COMPANY_JUNIOR = 'company_junior',
  POSITION_SENIOR = 'position_senior',
  MAN_ONLY = 'man_only',
  WOMAN_ONLY = 'woman_only',
  EU_CITIZEN = 'eu_citizen',
  /** @deprecated */
  NON_EU_CITIZEN = 'non_eu_citizen',
  NON_UA_PROTECTED = 'non_ua_protected',
}

export const perkMap: Map<PerkDataEnum | Primitive, Perks> = new Map(
  [
    [PerkDataEnum.ADULT, Perks.ADULT],
    [PerkDataEnum.BRANCHOFFICE_SENIOR, Perks.BRANCHOFFICE_SENIOR],
    [PerkDataEnum.COMPANY_SENIOR, Perks.COMPANY_SENIOR],
    [PerkDataEnum.COMPANY_JUNIOR, Perks.COMPANY_JUNIOR],
    [PerkDataEnum.POSITION_SENIOR, Perks.POSITION_SENIOR],
    [PerkDataEnum.MAN_ONLY, Perks.MAN_ONLY],
    [PerkDataEnum.WOMAN_ONLY, Perks.WOMAN_ONLY],
    [PerkDataEnum.NON_EU_CITIZEN, Perks.NON_EU_ONLY],
    [PerkDataEnum.EU_CITIZEN, Perks.EU_ONLY],
    [PerkDataEnum.NON_UA_PROTECTED, Perks.NON_UA_PROTECTED],
  ],
);

export const personDocumentToTymberPerk = (doc: PersonDocumentData): TymberPerk => {
  if (!doc.documentType) throw new Error('DocumentType is required to map PersonDocuments to TymberPerk');
  return {
    id: doc.documentType.id,
    title: doc.documentType?.display_name || doc.documentType?.name || '',
    time: {
      start: doc.valid_from || '',
      end: doc.valid_to || '',
    }
  };
};

export const perkDataToPerk = (p: Partial<PerkData>): Perk => ({
  id: (perkMap.has(p.id) && perkMap.get(p.id)) || 0,
  title: getPerkNameById(p.id),
  isDocument: false,
  is_visible: p.is_visible,
  group: p.group,
});

export const documentTypeToPerk = (doc: DocumentTypeData): Perk => ({
  id: doc.id,
  title: doc.name,
  isDocument: true,
});

export const applicationToRing = (application: ApplicationData) : Ring => {
  const { shift } = application;
  if (!shift) throw new Error("Application is missing informations about shift");

  const ring = shiftToRing(shift);
  if (!application.invitation) return ring;

  ring.credits = {
    hour: application.credits || 0,
    shift: calculateWorkTime(shift.start_time, shift.end_time) * ( application.credits || 0),
  };
  ring.userApplication = application;
  return ring;
}

export const shiftToRing = (shift: ShiftData): Ring => {

  const instructionNewcomers = shift.instruction_newcomers ? `Pro nováčky: ${shift.instruction_newcomers}` : null;

  let instructions : string = [
    shift.instruction,
    instructionNewcomers,
    shift.branchoffice?.instruction,
    shift.branchoffice?.parent?.instruction
  ].filter(instruction => instruction).join('\n\n')

  return {
    id: shift.id,
    title: shift.branchoffice?.parent?.display_name
    || shift.branchoffice?.parent?.name
    || shift.branchoffice?.display_name
    || shift.branchoffice?.name
    || '',
    position: shift.name,
    description: shift.description,
    instructions,
    contact: shift.contact_person || undefined,
    freePositions: shift.manShift?.filter((ms) => ms.application_id === null)
      .length || 0,
    time: {
      end: shift.end_time,
      start: shift.start_time,
    },
    duration: moment(shift.end_time).diff(shift.start_time, 'hour', true),
    durationOver: 0,
    durationCredit: 0,
    durationWithoutBreak: calculateWorkTime(shift.start_time, shift.end_time),
    address: getAddress(
      shift.branchoffice?.address || shift.branchoffice?.parent?.address,
    ),
    company: companyDataToCompanyRef(shift.company),
    money: {
      hour: shift.payment_base,
      shift:
        calculateWorkTime(shift.start_time, shift.end_time) * shift.payment_base,
    },
    credits: {
      hour: shift.credits,
      shift: calculateWorkTime(shift.start_time, shift.end_time) * shift.credits,
    },
    perks: [...(shift.documentType?.filter(x => !x.is_signable).map(documentTypeToPerk) || [])],
    utilities: [],
    claimed: false,
    surcharges: shift.surcharges,
    labels: shift.labels,
    state: 0, // TODO
    finishedState: 0, // TODO
    type: shift.type,
  };
};

export const attendanceToCredit = (attendance: AttendanceData): Credit => {
  return {
    id: attendance.id,
  company: companyDataToCompanyRef(attendance.application?.shift?.company),
  credits: Number(attendance.computedCredits?.toFixed(2)),

  time: {
    start: attendance.application?.shift?.start_time || '',
    end: attendance.application?.shift?.end_time || '',
  },
  workedHours: attendance.confirmed_time / 60,
  overTime: attendance.confirmed_overtime / 60,
  hourPayment: attendance.application?.shift?.payment_base || 0,
  processing: !!attendance.creditTransaction,
  pollId: 1, // TODO - connect to survey
}
};

// export const personLiabilityToLiability = (p: PersonLiabilityData): Liability => ({
//   id: p.id,
//   company: companyDataToCompanyRef(p.attendance?.application?.shift?.company),
//   description: p.description || '',
//   value: Number(p.amount || 0),
//   date: p.created_at,
// });

export const personDocumentDataToRingDocument = (d: PersonDocumentData): RingDocument => ({
  id: d.id,
  signed: {
    id: d.id,
    documentId: d.id,
    ringId: 2, // TODO
    time: {
      start: d.valid_from || '',
      end: d.valid_to || '',
    },
    tymbe: !!d.documentType?.is_signable,
  },
  template: `${d.id}`,
  title: d.name || d.documentType?.name || '',
  tymbe: !!d.documentType?.is_signable,
});

type FilterData = {
  location?: string;
  company?: string;
  date?: {
    gt?: string;
    lt?: string;
  };
  position?: string;
};

type MappedShiftFilterData = {
  start_time?: {
    $gte?: string,
    $lte?: string,
  },
  'shift.name'?: {
    $ilike: string,
  },
  ['$or']?: (
    { 'branchoffice:parent:address.zip'?: { $gte: string; $lte: string; }; }
    | { 'branchoffice:address.zip'?: { $gte: string; $lte: string; }; }
    | { 'company.name'?: {$ilike: string }; }
    | { 'company.display_name'?: {$ilike: string }; }
    | { 'branchoffice.name'?: {$ilike: string }; }
    | { 'branchoffice.display_name'?: {$ilike: string }; }
    | { 'branchoffice:parent.name'?: {$ilike: string }; }
    | { 'branchoffice:parent.display_name'?: {$ilike: string }; }
  )[],
  ['$and']?: (
    { $or: (
      { 'company.name'?: {$ilike: string }; }
      | { 'company.display_name'?: {$ilike: string }; }
      | { 'branchoffice.name'?: {$ilike: string }; }
      | { 'branchoffice.display_name'?: {$ilike: string }; }
      | { 'branchoffice:parent.name'?: {$ilike: string }; }
      | { 'branchoffice:parent.display_name'?: {$ilike: string }; }
      )[];
    } |
    { $or: (
      { 'branchoffice:parent:address.zip'?: { $gte: string; $lte: string; }; }
      | { 'branchoffice:address.zip'?: { $gte: string; $lte: string; }; }
      )[];
    }
  )[],
};

export const transformShiftFilter = (filter: FilterData) => {
  const mappedFilter: MappedShiftFilterData = {};
  if (filter.date) {
    mappedFilter.start_time = filter.date.gt ? { $gte: filter.date.gt } : undefined;
    mappedFilter.start_time = filter.date.lt ? { ...mappedFilter.start_time || {}, $lte: filter.date.lt } : mappedFilter.start_time;
  };

  if (filter.location && filter.company) {
    const companyFilter = { $ilike: `%${filter.company}%` };
    const [$gte, $lte] = filter.location.split('-');
    mappedFilter['$and'] = [
      { $or: [
        {'company.name': companyFilter },
        {'company.display_name': companyFilter },
        {'branchoffice.name': companyFilter },
        {'branchoffice.display_name': companyFilter },
        {'branchoffice:parent.name': companyFilter },
        {'branchoffice:parent.display_name': companyFilter },
        ]
      },
      { $or: [
        {'branchoffice:parent:address.zip': {$gte, $lte}},
        {'branchoffice:address.zip': {$gte, $lte}}
        ]
      }
    ];
  };

  if (filter.company && !filter.location) {
    const companyFilter = { $ilike: `%${filter.company}%` };
    mappedFilter['$or'] = [
      {'company.name': companyFilter },
      {'company.display_name': companyFilter },
      {'branchoffice.name': companyFilter },
      {'branchoffice.display_name': companyFilter },
      {'branchoffice:parent.name': companyFilter },
      {'branchoffice:parent.display_name': companyFilter },
    ];
  };

  if (filter.location && !filter.company) {
    const [$gte, $lte] = filter.location.split('-');
    mappedFilter['$or'] = [{'branchoffice:parent:address.zip': {$gte, $lte}}, {'branchoffice:address.zip': {$gte, $lte}}]
  };

  if (filter.position) {
    mappedFilter['shift.name'] = { $ilike: `%${filter.position}%` };
  };

  return mappedFilter;
};

type MappedApplicationFilterData = {
  ['shift.start_time']?: {
    $gte?: string,
    $lte?: string,
  },
  'shift:company.name'?: {
    $ilike: string,
  },
  ['shift.name']?: {
    $ilike: string,
  },
};

export const transformApplicationFilter = (filter: FilterData) => {
  const mappedFilter: MappedApplicationFilterData = {};
  if (filter.date) {
    mappedFilter['shift.start_time'] = filter.date.gt ? { $gte: filter.date.gt } : undefined;
    mappedFilter['shift.start_time'] = filter.date.lt ?
    { ...mappedFilter['shift.start_time'] || {}, $lte: filter.date.lt } : mappedFilter['shift.start_time'];
  }
  if (filter.company) {
    mappedFilter['shift:company.name'] = { $ilike: `%${filter.company}%` };
  }
  if (filter.position) {
    mappedFilter['shift.name'] = { $ilike: `%${filter.position}%` };
  }
  return mappedFilter;
};

const getQuestionType = (questionType: SurveyQuestionType): TSurveyQuestionType => {
  switch (questionType) {
    case SurveyQuestionType.RADIO:
      return TSurveyQuestionType.RADIO;
    case SurveyQuestionType.CHECKBOX:
      return TSurveyQuestionType.CHECKBOX;
    case SurveyQuestionType.LONG_TEXT:
      return TSurveyQuestionType.TEXT_AREA;
    case SurveyQuestionType.TEXT:
    default:
      return TSurveyQuestionType.TEXT;
  }
};

export const questionDataToQuestion = (q: SurveyQuestionData): SurveyQuestion => ({
  id: q.id,
  question: q.question.cs,
  type: getQuestionType(q.type),
  state: SurveyQuestionState.ACTIVE,
  options: q.options?.map(o => ({
    id: o.id,
    name: o.name.cs,
    description: o.description,
  })) || [],
});

export const surveyDataToSurvey = (survey: SurveyData): Survey => ({
  id: survey.id,
  title: survey.name,
  company: companyDataToCompanyRef(survey.company),
  questions: survey.questions?.map(questionDataToQuestion) || [],
});

// Map TymberDocument.State by PersonDocument data
export const getPersonDocumentState = (data: PersonDocumentData): TymberDocumentStates => {
  const isInfiniteValidOrBeforeValidTo = !data.valid_to || moment().isBefore(data.valid_to);

  if (data.reviewed_at && moment(data.valid_to) < moment()) return TymberDocumentStates.INVALIDATED;
  if (data.reviewed_at && data.approved && isInfiniteValidOrBeforeValidTo) return TymberDocumentStates.APPROVED;
  if (data.reviewed_at && data.approved === false) return TymberDocumentStates.REJECTED;
  if ((!data.reviewed_at || !data.reviewed_by) && !data.approved) return TymberDocumentStates.NOT_APPROVED;
  return TymberDocumentStates.NOT_APPROVED;
};

// Map new PersonDocument structure to old TymberDocument
export const mapPersonDocumentToTymberDocument = (data: PersonDocumentData): TymberDocument => ({
    id: data.id,
    state: getPersonDocumentState(data),
    parts: data.personDocumentFile?.map((file) => ({
      id: file.file,
      title: data.name || data.documentType?.display_name || data.documentType?.name || data.documentType?.type || 'Beze jména', // file itself doesn't have name in the new system
      size: '0', // TODO: We dont know this probably (we dont have this information in the new db)
    })) || [],
    perk: {
      id: data.documentType?.id || 0,
      title: data.documentType?.display_name || data.documentType?.name || data.name || '',
    },
    time: {
      start: data.valid_from || '',
      end: data.valid_to || '',
    },
  }
)
