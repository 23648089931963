import { TimeStampsData } from './jsonSchema.utils';
import { PerkData } from './perk.interface';
import { CompanyData } from './company.interface';
import { BranchofficeData } from './branchoffice.interface';
import { PositionData } from './position.interface';
import { PaySupplementRules } from './pay-supplement.interface';
import { DocumentTypeData } from './document-type.interface';
import { JobClassificationIds } from './job-classification-ids.interface';

export interface ShiftTemplateData extends TimeStampsData {
  id: number;
  company_id: number;
  position_id?: number | null;
  template_name: string;
  name: string | null;
  payment_base: number;
  credits: number;
  credits_min: number;
  invitation_credits: number;
  billing_rate: number;
  margin: number;
  description: string;
  instruction: string;
  instruction_newcomers: string;
  job_classification_ids: JobClassificationIds;
  publish_at?: string | null;
  pay_supplement: PaySupplementRules;
  expected_hpp_weekly_hours?: number | null;
  maximum_hpp_trial_period?: number | null;
  labels: string[] | null;

  company?: CompanyData;
  branchoffice?: BranchofficeData[];
  position?: PositionData;
  perk?: PerkData[];
  documentType?: DocumentTypeData[];
}

export enum ShiftLabels {
  PHYSICALLY_CHALLENGING_JOB = 'physically_challenging_job',
}
