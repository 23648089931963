import { PaySupplement } from '@tymbe/legislatives/PaySupplement';
import { ApplicationData } from '@tymbe/schema/application.interface';
import { ValidateResp } from '@tymbe/schema/claim-shift-precheck.interface';
import { DocumentTypeData } from '@tymbe/schema/document-type.interface';
import { CountryCodes, ForeignerOrigin, ShiftType } from '@tymbe/schema/enums';
import { Optional } from 'utility-types';
import { ValidationError } from 'yup';

export interface Login {
  token: string;
  id: number;
  email: string;
  gender: Genders;
  birthDate: string;
}

export interface LoginReq {
  username: string;
  password: string;
}

export interface ApiBaseError {
  status: number;
  path: string;
  method: string;
  message: string;
  id: string;
}

export class ApiError<D = any> implements ApiBaseError {
  status: number;

  path: string;

  method: string;

  message: string;

  id: string;

  data?: D;

  constructor(base: ApiBaseError, data?: D) {
    this.status = base.status;
    this.path = base.path;
    this.method = base.method;
    this.message = base.message;
    this.id = base.id;
    this.data = data;
  }
}

export type ApiValidationError = ApiError<ValidationError[]>;

export interface RegistrationData {
  sureName: string;
  familyName: string;
  idCardNumber: string;
  bankAccountNumber: string;
  bankNumber: string;
  paymentNote: string;
  idExpirationDate: string;
  birthDate: string;
  gender: Genders;
  country: string;
}

export interface Tymber {
  id: number;
  prefixName: string;
  sureName: string;
  familyName: string;
  suffixName: string;
  email: string;
  phone: string;
  gender: Genders;
  birthDate: string;
  idCardNumber: string;
  idCardValidTo: string;
  address: Address;
  contactAddress: Address;
  nationality: CountryCodes;
  perks: TymberPerk[];
  bankAccountNumber: string;
  bankNumber: string;
  paymentNote: string;
  state: TymberStates;
  shirtSize: string;
  shoesSize: string;
  glasses: boolean;
  invitations?: number;
  claimed?: number;
  foreignerOrigin?: ForeignerOrigin | null;
  email_notification_allowed: boolean;
}
export const enum ContactType {
  EMAIL = 'email',
  MOBILE_PHONE = 'mobile_phone',
  LANDLINE = 'landline',
  OTHER = 'other',
}
export interface TimeStampsData {
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface PersonContactData extends TimeStampsData {
  id: number;
  person_id: number;
  type: ContactType;
  name: string;
  value: string;
}

export enum TymberStates {
  REGISTRATION = 1, // zaklad, bez emailu
  POST_REGISTRATION, // zaklad, email
  ACTIVE, // all gud = overeny doklad
  BANNED,

  NO_VALID_ID = 100,
}

export interface RegistrationDataRequest {
  bankAccountNumber: string;
  bankNumber: string;
  paymentNote: string;
  idExpirationDate: string;
  expiryDateCheck: number;
  birthDateCheck: number;
  idNumberCheck: number;
  finalCheck: number;
}

export interface SaveTymberDocumentDataRequest {
  did?: string | null;
  document_type_id: DocumentTypeData['id'];
  from?: string | null;
  to?: string | null;
  files: File[];
}

export interface Address {
  street: string[];
  city: string;
  country: CountryCodes;
  zip: string;
}

export interface Ring {
  id: number;
  title: string;
  position: string;
  description: string;
  instructions: string;
  contact?: string;
  freePositions: number;
  time: TimeRange;
  duration: number;
  durationOver: number;
  durationCredit: number;
  durationWithoutBreak: number;
  address: Address;
  company: CompanyRef;
  money: Money;
  credits: Money;
  perks: Perk[];
  utilities: CompanyUtility[];
  claimed: boolean;
  state: ClaimedStates;
  finishedState: FinishedStates;
  userApplication?: ApplicationData;
  surcharges: PaySupplement[];
  type: ShiftType;
  labels: string[] | null;
}

export interface TimeRange {
  start: string;
  end: string;
}

export interface Money {
  hour: number;
  shift: number;
}

export interface CompanyRef {
  id: number;
  oz: number | null;
  name: string;
  logo: string | null;
}

export interface Perk {
  id: number;
  title: string;
  isDocument?: boolean;
  is_visible?: boolean;
  group?: string | null;
}

export interface TymberPerk extends Optional<Perk, 'title'> {
  time: TimeRange;
}

export interface RingDocumentSigned {
  id: number;
  documentId: RingDocument['id'];
  ringId: Ring['id'];
  time: TimeRange;
  tymbe: boolean;
}

export type Answer = {
  value: string,
  label: string
};

export type TestQuestion = {
  question: string,
  answers: Answer[],
  correct: string,
};

export type TestJson = {
  evaluation: {
    passed_excellent: number,
    passed: number,
    failed: number
  }
  testQuestions: TestQuestion[]
};

export interface RingDocument {
  id: number;
  signed?: RingDocumentSigned;
  template: string;
  title: string;
  tymbe: boolean;
  form_definition: TestJson | null;
}

export type RingShiftClaimPrecheck = ValidateResp & { contracts: RingDocument[] };

export interface TymberDocumentPart {
  // id: number;
  id: string;
  title: string;
  size: string;
}

export interface TymberDocument {
  id: number;
  perk: Perk;
  time: TimeRange;
  parts: TymberDocumentPart[];
  state: TymberDocumentStates;
}

export interface CompanyUtility {
  id: number;
  title: string;
  amortization: number;
  price: number;
  companyId: number;
  note: string;
}

export interface TymberUtility {
  id: number;
  title: string;
  amortization: number;
  amortizationRemaining: number;
  price: number;
  size: string;
  issued: string;
  company: CompanyRef;
}

export interface Liability {
  id: number;
  company: CompanyRef;
  description: string;
  value: number;
  date: string;
}

export interface PayLiabilitiesResponse {
  liabilities: Liability[];
  credits: Credit[];
}

export interface Credit {
  id: number;
  company: CompanyRef;
  credits: number;
  time: TimeRange;
  workedHours: number;
  overTime: number;
  hourPayment: number;
  pollId: number;
  processing: boolean;
}

export interface Survey {
  id: number;
  title: string;
  company: CompanyRef;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  id: number;
  question: string;
  type: SurveyQuestionType;
  state: SurveyQuestionState;
  options: SurveyQuestionOption[];
}

export interface SurveyQuestionOption {
  id: number;
  name: string;
  description: string | null;
}

export interface SurveyAnswer {
  id: Survey['id'];
  answers: { [K: string]: string | number | boolean[]; };
}

export interface BucketFile {
  name: string;
  url: string
}

export enum SurveyQuestionType {
  TEXT,
  TEXT_AREA = 1,
  RADIO,
  CHECKBOX,
  DROPDOWN,
}

export enum SurveyQuestionState {
  ACTIVE = 1,
  DISABLED,
}

export enum Genders {
  MAN = 1,
  WOMAN,
}

export enum ClaimedStates {
  CLAIMED = 1,
  PENDING_INVITATION,
  CANCELED_EARLY,
  CANCELED_LATE,
  REVOKED_INVITATION,
}

export enum FinishedStates {
  UNFINISHED,
  AWAITING_CONFIRMATION,
  CONFIRMED,
  AWAITING_PAYMENT,
  COMPLETED,
  ABSENT_OK = 5,
  ABSENT_NOK,
  ALCOHOL_DRUGS,
  CANCELED_EARLY,
  CANCELED_LATE,
  SYSTEM_CANCELED,
}

export enum TymberDocumentStates {
  NOT_APPROVED = 1,
  APPROVED,
  REJECTED,
  INVALIDATED,
}

export enum Perks {
  MAN_ONLY = 1,
  ADULT,
  HEALTH_CARD,
  CRIMINAL_RECORD,
  DRIVING_LICENSE_B,
  FORKLIFT,
  MINIFORKLIFT,
  STUDENT,
  WOMAN_ONLY,
  ID_CARD,
  ANTIGEN_TEST,
  HEALTH_INSURANCE,
  /** @deprecated */
  NON_EU_ONLY,
  BRANCHOFFICE_SENIOR,
  COMPANY_SENIOR,
  POSITION_SENIOR,
  EU_ONLY,
  NON_UA_PROTECTED,
  COMPANY_JUNIOR,
}

export const PerkId = {
  ANY_GENDER: 'any_gender',
  MAN_ONLY: 'man_only',
  WOMAN_ONLY: 'woman_only',
  ADULT: 'adult',
  ANY_AGE: 'any_age',
  ANY_QUALIFICATION: 'any_qualification',
  COMPANY_SENIOR: 'company_senior',
  COMPANY_JUNIOR: 'company_junior',
  BRANCHOFFICE_SENIOR: 'branchoffice_senior',
  POSITION_SENIOR: 'position_senior',
  EU_CITIZEN: 'eu_citizen',
  /** @deprecated */
  NON_EU_CITIZEN: 'non_eu_citizen',
  NON_UA_PROTECTED: 'non_ua_protected',
};

export const getPerkNameById = (perkId?: string) => {
  switch (perkId) {
    case PerkId.ANY_GENDER: return 'Pro všechny';
    case PerkId.MAN_ONLY: return 'Jen pro muže';
    case PerkId.WOMAN_ONLY: return 'Jen pro ženy';
    case PerkId.ADULT: return '18+';
    case PerkId.COMPANY_SENIOR: return 'Pracoval pro firmu';
    case PerkId.COMPANY_JUNIOR: return 'Pouze pro nováčky';
    case PerkId.BRANCHOFFICE_SENIOR: return 'Pracoval na pobočce';
    case PerkId.POSITION_SENIOR: return 'Pracoval na pozici';
    case PerkId.ANY_QUALIFICATION: return 'Pro všechny';
    case PerkId.ANY_AGE: return '16+';
    case PerkId.EU_CITIZEN: return 'Občanství v EU';
    case PerkId.NON_EU_CITIZEN: return 'Občanství mimo EU';
    case PerkId.NON_UA_PROTECTED: return 'Mimo dočasnou ochranu UA';
    default: return '';
  }
};
