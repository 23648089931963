import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { CardSubHeader } from './Card/CardSubHeader';
import Spinner from './Spinner';
import { H2, H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import moment from 'moment';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '../styledComponents';
import { PaymentAuthorizedDetailShift } from '@tymbe/schema/salary-calculation.interface';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { getPaySupplementTranslation } from './WageDetail/translations';

const RingHeader = styled(CardHeader)<{ toggled?: boolean }>`
  display: flex;
  flex-flow: row;
  align-items: stretch;

  & > * {
    flex-grow: 1;
    align-self: center;

    &:last-child, &${Icon} {
      flex-grow: 0;
    }
    &:last-child {
      text-align: right;
      margin-left: 2rem;
    }
  }
`;

const RingSubHeader = styled(CardSubHeader)`
  display: flex;
  flex-flow: row;
  align-items: stretch;
  font-size: 1.1rem;

  & > * {
    flex-grow: 1;
    margin-right: 1rem;

    &:last-child, &:first-child {
      flex-grow: 0;
      align-self: center;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const RingFooter = styled(CardFooter)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  & > *:nth-child(even) {
    text-align: right;
  }
`;

type WageDetailBaseProps = {
  children?: React.ReactNode;
  data: PaymentAuthorizedDetailShift;
  loading?: boolean;
  expanded?: boolean;
};

export const WageShift = styled(
  ({ children, data: wageDetail, loading, expanded: exp, ...rest }: WageDetailBaseProps) => {
    // tslint:disable-next-line:no-shadowed-variable we need to use this name because of automation
    const intl = useIntl();

    const startTime = moment(wageDetail.startTime);
    const endTime = moment(wageDetail.finishTime);
    const duration = moment(endTime).diff(startTime, 'hours');

    const groupedSupplements = wageDetail.paySupplements.reduce<Record<PaySupplementType, number>>((acc, supplement) => {
      acc[supplement.paySupplement.type] += supplement.totalGrossSalary;
      return acc;
    }, {
      [PaySupplementType.Holiday]: 0,
      [PaySupplementType.Night]: 0,
      [PaySupplementType.Weekend]: 0,
    });
    const supplementSum = Object.values(groupedSupplements).reduce((acc, val) => acc + val, 0);

    return (
      <Card {...rest}>
        {loading && <Spinner />}
        <RingHeader>
          <H2 style={{ whiteSpace: 'nowrap' }}>{intl.formatMessage(
            {
              defaultMessage: '{date, date, short}',
              description: 'Datum brigády v hlaviče karty brigády, používá se co nejkratší zápis data, např:"10.12.22"',
            },
            {
              date: startTime,
            },
          )}</H2>
          <H3>{
              intl.formatMessage(
                {
                  defaultMessage: '{date, time, ::ccccc}',
                  description: 'Název dne týdnu v hlaviče karty brigády, např: "pondělí"',
                },
                {
                  date: startTime,
                },
              )
          }</H3>
        </RingHeader>
        <CardContent style={{ paddingBottom: 0 }}>
          <div>
            {intl.formatMessage(
              {
                defaultMessage: `<strong>Hodinová mzda</strong>: {hours_rate} Kč/h`,
                description: 'Hodinová mzda v korunách.'
              },
              {
                hours_rate: intl.formatNumber(wageDetail.hourlyRate, {
                  style: 'decimal',
                  minimumFractionDigits: wageDetail.hourlyRate % 1 ? 2 : 0,
                  maximumFractionDigits: wageDetail.hourlyRate % 1 ? 2 : 0,
                }) }
            )}
          </div>
          <div>
            {intl.formatMessage(
              {
                defaultMessage: `<strong>Odpracováno bez přestávky</strong>: {totalNetHours, number , ::.## unit/hour}`,
                description: 'Počet odpracovaných hodin bez přestávky.'
              },
              {
                totalNetHours: wageDetail.workTimeTotalNetMinutes / 60
              }
            )}
          </div>
          <hr />
          <div>
            <div>
              {intl.formatMessage(
                {
                  defaultMessage: `<strong>Hrubá odměna celková:</strong> {totalGrossSalary} Kč`,
                  description: 'Celková hrubá odměna.'
                },
                {
                  totalGrossSalary: intl.formatNumber(wageDetail.totalGrossSalary, {
                    style: 'decimal',
                    minimumFractionDigits: wageDetail.totalGrossSalary % 1 ? 2 : 0,
                    maximumFractionDigits: wageDetail.totalGrossSalary % 1 ? 2 : 0,
                  }) }
              )}
            </div>
            {supplementSum > 0 && (
              <>
                <div>
                  {intl.formatMessage(
                    {
                      defaultMessage: `Základní: {baseGrossSalary} Kč`,
                      description: 'Základ hrubé odměny.'
                    },
                    {
                      baseGrossSalary: intl.formatNumber(wageDetail.totalGrossSalary - supplementSum, {
                        style: 'decimal',
                        minimumFractionDigits: wageDetail.totalGrossSalary % 1 ? 2 : 0,
                        maximumFractionDigits: wageDetail.totalGrossSalary % 1 ? 2 : 0,
                      }) }
                  )}
                </div>
                {Object.entries(groupedSupplements).map(([type, amount]) =>
                  amount > 0 && (
                    <div key={`${type}_${amount}`}>
                      {intl.formatMessage(
                        {
                          defaultMessage: `{name}: {amount} Kč`,
                          description: 'Příplatek.'
                        },
                        {
                          name: intl.formatMessage(getPaySupplementTranslation(type as PaySupplementType)),
                          amount: intl.formatNumber(amount, {
                            style: 'decimal',
                            minimumFractionDigits: wageDetail.totalGrossSalary % 1 ? 2 : 0,
                            maximumFractionDigits: wageDetail.totalGrossSalary % 1 ? 2 : 0,
                          }) }
                      )}
                    </div>
                  )
                )}
              </>
            )}
          </div>
          {children}
        </CardContent>
        <RingFooter />
      </Card>
    );
  })`max-width: 450px;`;
