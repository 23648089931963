import { call, cancel, cancelled, fork, put, take, takeEvery } from '@redux-saga/core/effects';
import {
  Actions,
  downloadTymberDocumentFailure,
  DownloadTymberDocumentRequestAction,
  downloadTymberDocumentSuccess,
  getTymberDocumentsFailure,
  GetTymberDocumentsRequestAction,
  getTymberDocumentsSuccess,
  invalidateTymberDocumentFailuer,
  InvalidateTymberDocumentRequestAction,
  invalidateTymberDocumentSuccess,
  moreTymberDocumentsFailure,
  MoreTymberDocumentsRequestAction,
  moreTymberDocumentsSuccess,
  saveTymberDocumentFailure,
  SaveTymberDocumentRequestAction,
  saveTymberDocumentSuccess,
} from '../actions/tymberDocuments';
import Axios, { AxiosResponse } from 'axios';
import { StoreState, componentsSelector } from '../reducers';
import { tymberDocumentsSelector } from '../reducers/components';
import { SagaIterator } from 'redux-saga';
import { select, takeLatest } from 'redux-saga/effects';
import { handleApiError, initDownload } from './utils';
import api from '../services/api';
import { ApiError, TymberDocument } from '../types/Tymbe';

export function* fetchTymberDocuments(action: GetTymberDocumentsRequestAction): SagaIterator {
  try {
    const response: AxiosResponse<TymberDocument[]> = yield call(api.getTymberDocuments);
    yield put(getTymberDocumentsSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(getTymberDocumentsFailure(error));
    }
  }
}

export function* fetchMoreTymberDocuments(action: MoreTymberDocumentsRequestAction): SagaIterator {
  const tymberDocumentsIds = yield select((state: StoreState) =>
    tymberDocumentsSelector(componentsSelector(state)!)
  );
  const from = tymberDocumentsIds?.data?.length || 0;
  try {
    const response: AxiosResponse<TymberDocument[]> = yield call(
      api.getTymberDocuments,
      '',
      from
    );
    yield put(moreTymberDocumentsSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(moreTymberDocumentsFailure(error));
    }
  }
}

export function* fetchTymberDocumentFile(action: DownloadTymberDocumentRequestAction): SagaIterator {
  const { id } = action;
  try {
    const response: AxiosResponse<Blob> = yield call(api.getTymberDocumentFile, id);
    initDownload(response, `doklad_${id}`);
    yield put(downloadTymberDocumentSuccess(id));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(downloadTymberDocumentFailure(id, error));
    }
  }
}

export function* invalidateTymberDocument(action: InvalidateTymberDocumentRequestAction): SagaIterator {
  const { id } = action;
  try {
    const response: AxiosResponse<TymberDocument> = yield call(api.invalidateTymberDocument, id);
    yield put(invalidateTymberDocumentSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(invalidateTymberDocumentFailuer(id, error));
    }
  }
}

export function* saveTymberDocument(action: SaveTymberDocumentRequestAction): SagaIterator {
  const { data, config } = action;
  const { cancel: cancelRequest, token: cancelToken } = Axios.CancelToken.source();
  try {
    const response: AxiosResponse<TymberDocument> = yield call(api.saveTymberDocument, data, {
      ...config,
      cancelToken,
    });
    yield put(saveTymberDocumentSuccess(response.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(saveTymberDocumentFailure(error));
    }
  } finally {
    if (yield cancelled()) {
      console.log('task cancelled');
      cancelRequest();
      // TODO: check if it's ok
      // yield put(saveTymberDocumentFailure(null));
    }
  }
}

export function* watchGetTymberDocumentsRequest() {
  yield takeEvery(Actions.GET_TYMBER_DOCUMENTS_REQUEST, fetchTymberDocuments);
}

export function* watchMoreTymberDocumentsRequest() {
  yield takeLatest(Actions.MORE_TYMBER_DOCUMENTS_REQUEST, fetchMoreTymberDocuments);
}

export function* watchDownloadTymberDocument() {
  yield takeEvery(Actions.DOWNLOAD_TYMBER_DOCUMENT_REQUEST, fetchTymberDocumentFile);
}

export function* watchInvalidateTymberDocumentRequest() {
  yield takeEvery(Actions.INVALIDATE_TYMBER_DOCUMENT_REQUEST, invalidateTymberDocument);
}

export function* watchSaveTymberDocumentRequest(): SagaIterator {
  let task;
  while (true) {
    const action = yield take([
      Actions.SAVE_TYMBER_DOCUMENT_FAILURE,
      Actions.SAVE_TYMBER_DOCUMENT_SUCCESS,
      Actions.SAVE_TYMBER_DOCUMENT_CANCEL,
      Actions.SAVE_TYMBER_DOCUMENT_REQUEST,
    ]);

    if (action.type === Actions.SAVE_TYMBER_DOCUMENT_REQUEST) {
      task = yield fork(saveTymberDocument, action);
    }

    if (action.type === Actions.SAVE_TYMBER_DOCUMENT_CANCEL && task) {
      yield cancel(task);
      task = null;
    }
  }
}
