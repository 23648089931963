import { Card } from './Card';
import { CardContent } from './Card/CardContent';
import { CardFooter } from './Card/CardFooter';
import { CardHeader } from './Card/CardHeader';
import { Button } from './Forms/Button';
import { H1 } from './Styles/BasicElements';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { styled } from '../styledComponents';
import { ApiError } from '../types/Tymbe';

export type ErrorProps = {
  error: ApiError | null;
  children?: ReactNode;
  onClose?: () => void;
  buttonText?: string;
  title?: string
};

export const ErrorCard = styled(Card)`
  min-width: 30%;
  margin: 4rem auto auto;
`;

export const Error: React.FC<ErrorProps> = ({
  error,
  children,
  onClose,
  buttonText,
  title,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);

  return (
    <ErrorCard color={'danger'}>
      <CardHeader><H1>{
        title
        || intl.formatMessage({
          defaultMessage: 'Chyba',
          description: 'Titulek chybového okna',
        })
      }</H1></CardHeader>
      <CardContent>
        {children || error?.message}
      </CardContent>
      <CardFooter style={{ textAlign: 'right' }}>
        <Button onClick={onClose || handleBack}>{
            buttonText
            || intl.formatMessage({
              defaultMessage: 'Zpět',
              description: 'Tlačítko pro zavření chybového okna',
            })
        }</Button>
      </CardFooter>
    </ErrorCard>
  );
};
