import { call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import {
  ActionTypes,
  ringClaimFailure,
  RingClaimRequestAction,
  ringClaimSuccess,
  ringFailure,
  RingRequestAction,
  ringSuccess,
} from '../actions/data/rings';
import {
  MORE_RINGS_REQUEST,
  moreRingsError,
  MoreRingsRequestAction,
  moreRingsSuccess,
  RINGS_REQUEST,
  ringsFailure,
  RingsRequestAction,
  ringsSuccess,
} from '../actions/ring';
import { AxiosResponse } from 'axios';
import { componentsSelector, StoreState } from '../reducers';
import { ringsSelector } from '../reducers/components';
import { SagaIterator } from 'redux-saga';
import { handleApiError } from './utils';
import api from '../services/api';
import { ApiError, Ring } from '../types/Tymbe';

export function* fetchRings(action: RingsRequestAction): SagaIterator {
  const { filters } = action;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getRings, filters);
    yield put(ringsSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(ringsFailure(error));
    }
  }
}

export function* fetchMoreRings(action: MoreRingsRequestAction): SagaIterator {
  const { filters } = action;
  const ringIds = yield select((state: StoreState) => ringsSelector(componentsSelector(state)!));
  const from = ringIds?.data?.length || 0;
  try {
    const rings: AxiosResponse<Ring[]> = yield call(api.getRings, { ...filters, $skip: from });
    yield put(moreRingsSuccess(rings.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(moreRingsError(error));
    }
  }
}

export function* fetchRing(action: RingRequestAction): SagaIterator {
  const { ringId } = action;
  try {
    const ring: AxiosResponse<Ring> = yield call(api.getRing, ringId);
    yield put(ringSuccess(ring.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(ringFailure(ringId, error));
    }
  }
}

export function* claimRing(action: RingClaimRequestAction): SagaIterator {
  const { ringId } = action;
  try {
    const ring: AxiosResponse<Ring> = yield call(api.claimRing, ringId);
    yield put(ringClaimSuccess(ring.data));
  } catch (e) {
    const error = yield handleApiError(e as ApiError, action);
    if (error) {
      yield put(ringClaimFailure(ringId, error));
    }
  }
}

export function* watchRingRequest() {
  yield takeEvery(ActionTypes.RING_REQUEST, fetchRing);
}

export function* watchRingsRequest() {
  yield takeLatest(RINGS_REQUEST, fetchRings);
}

export function* watchRingClaimRequest() {
  yield takeEvery(ActionTypes.RING_CLAIM_REQUEST, claimRing);
}

export function* watchMoreRingsRequest() {
  yield takeLatest(MORE_RINGS_REQUEST, fetchMoreRings);
}
