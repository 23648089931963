import { Icon } from './Styles/Icon';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from '../styledComponents';
import { Perk as PerkData, Ring, Tymber } from '../types/Tymbe';
import { hasPerkFulfilled } from '../utils';

export type PerkProps = PerkData & {
  tymber: Tymber;
  hideLink?: boolean;
  ring?: Ring;
};

const Perk = styled.div<{ fulfilled?: boolean }>`
  color: ${({ fulfilled, theme }) => !fulfilled && theme.colors.danger || null};
  margin-top: 1rem;
  margin-left: 1rem;

  & > span {
    color: ${({ theme }) => theme.bodyColor};
  }
`;

const PerkComponent = ({ id, title, tymber, hideLink, ring, isDocument }: PerkProps) => {
  const intl = useIntl();
  const fulfilled = hasPerkFulfilled(tymber, id, ring, isDocument);

  return (
    <Perk fulfilled={fulfilled}>
      {fulfilled ?
        <Icon id={'finished'} background={'primary'} size={'1.5em'} style={{ marginRight: '.5rem' }} /> :
        <Icon id={'attention'} background={'danger'} size={'1.5em'} style={{ marginRight: '.5rem' }} />}
      {title}
      {!fulfilled && !hideLink && <span> {
        intl.formatMessage(
          {
            defaultMessage: '(nahrej do svého <link>profilu</link>)',
            description: 'Text zobrazeny u nesplněná podmínky,' +
              ' např: "(!) Výpis z rejstříku trestů (nahrej do svého profilu)"',
          },
          { link: chunks => <Link to={'/locker'}>{chunks}</Link> },
        )
      }</span>}
    </Perk>
  );
};

export { PerkComponent as Perk };
