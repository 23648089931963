import React from 'react';
import { styled } from '../styledComponents';

const Utility = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
`;

export type UtilityProps = {
  title: string;
};

const UtilityComponent = ({ title }: UtilityProps) => {
  return (
    <Utility>
      {title}
    </Utility>
  );
};

export { UtilityComponent as Utility };
