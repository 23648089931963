import { H3 } from './Styles/BasicElements';
import { Icon } from './Styles/Icon';
import { Utility } from './Utility';
import React from 'react';
import { useIntl } from 'react-intl';
import { CompanyUtility } from '../types/Tymbe';

export type UtilityListProps = {
  utilities: CompanyUtility[];
};

export const UtilityList = ({ utilities }: UtilityListProps) => {
  const intl = useIntl();
  return (
    <div>
      <H3>
        <Icon id={'utilities'} size={'1.5em'} style={{ marginRight: '.5rem' }} />
        {intl.formatMessage({
          defaultMessage: 'Vyžadované pomůcky',
          description: 'Seznam vyžadovaných pomůcek na brigádě (tričko, boty, ...)',
        })}
      </H3>
      {utilities.map(utility => <Utility
        key={utility.id}
        title={utility.title}
      />)}
    </div>
  );
};
