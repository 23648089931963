export enum WorkShiftCheckFlags {
  /** V průběhu výpočtu došlo k výjimce, chyba je nejspíše v datech nebo v logice. */
  EXCEPTION = 'X00',

  /** Není žádná smlouva na nabídce. */
  NO_CONTRACTS = 'C00',

  /** Podepsaná smlouva neodpovídá požadované smlouvě dle nabídky. */
  CONTRACTS_MISMATCH = 'C01',
  /** Existuje více nekompatibilních smluv ve stejný moment. */
  CONTRACTS_OVERLAP = 'C02',
  /** Existovaly by směny na DPP později než směny na DPČ nebo HPP. */
  CONTRACTS_DOWNGRADE = 'C03',
  /** Šablona smlouvy nebyla schválena k opakovanému použití. */
  CONTRACT_RENEWAL_DISALLOWED = 'C05',
  /** Tento typ smlouvy není povolen. */
  CONTRACT_NOT_ALLOWED = 'C06',

  /** Bylo zváženo více smluv, ale žádná neprošla. */
  MULTIPLE_DECLINES = 'D01',

  /** Směna překračuje povolenou délku. */
  SHIFT_TOO_LONG = 'S01',
  /** Je přihlášená překrývající se směna. */
  SHIFTS_OVERLAP = 'S02',
  /** Je přihlášená příliš blízká směna. */
  SHIFTS_TOO_CLOSE = 'S03',
  /** Moc směn / málo odpočinku. */
  INSUFFICIENT_BREAK = 'S04',

  /** Neplnoletý zaměstnanec. */
  EMPLOYEE_UNDERAGE = 'E01',
  /** Neplnoletý zaměstnanec na noční směně. */
  EMPLOYEE_UNDERAGE_IN_NIGHT_SHIFT = 'E02',
  /** Dítě, nezpůsobilé k práci. */
  EMPLOYEE_UNDER_15 = 'E03',
  /** Neplnoletý zaměstnanec nemá potvrzení o studiu. */
  EMPLOYEE_15_BUT_NOT_STUDYING = 'E04',

  /** Bude překročen (umělý) limit hrubé mzdy za měsíc. */
  SALARY_LIMIT_EXCEEDED = 'L01',
  /** Bude překročen (umělý) limit hrubé mzdy za měsíc, dosud ještě překročen není. */
  SALARY_LIMIT_EXCEEDED_NOW = 'L01n',
  /** Bude překročen limit odpracovaného času na smlouvu. */
  TIME_LIMIT_EXCEEDED = 'L02',

  /** Nemusí platit sociální a zdravotní pojištění. */
  EXEMPT_FROM_INSURANCE = 'I01',
}
